import { Restaurant } from "./type";

export const restaurants: Restaurant[] = [
  {
    title: "Sate H. Arifin",
    totalPrice: 25000,
    category: ["eatery"],
    menus: [
      { name: "Sate ayam", price: 15000 },
      { name: "Gule kambing", price: 10000 },
      { name: "Nasi", price: 7000 },
      { name: "Es Teh", price: 5000 },
      { name: "Kerupuk", price: 1000 },
    ],
    ourReview: { rating: { derby: 4, riska: 4.1 } },
    lastVisit: "2024-08-04",
  },
  {
    title: "Lalapan Tjap Tiga Petir",
    totalPrice: 32000,
    category: ["eatery"],
    menus: [
      { name: "Ayam", price: 15000 },
      { name: "Lele", price: 7000 },
      { name: "Tahu", price: 1000 },
      { name: "Hati", price: 3000 },
      { name: "Terong", price: 5000 },
      { name: "Sate Usus", price: 7000 },
      { name: "Sate Bakso", price: 7000 },
      { name: "Sate Paru", price: 7000 },
      { name: "Nasi Uduk", price: 7000 },
    ],
    ourReview: { rating: { derby: 4, riska: 4.4 } },
    lastVisit: "2024-02-21",
  },
  {
    title: "Ketoprak Agus",
    totalPrice: 30000,
    category: ["eatery"],
    menus: [
      { name: "Ketoprak", price: 12000 },
      { name: "Ketoprak telor", price: 15000 },
    ],
    ourReview: { rating: { derby: 3.9, riska: 4.7 } },
  },
  {
    title: "Seblak Mojang",
    totalPrice: 28000,
    category: ["eatery"],
    menus: [{ name: "Seblak komplit", price: 28000 }],
    ourReview: { rating: { derby: 2.5, riska: 4.5 } },
    lastVisit: "2024-09-26",
  },
  {
    title: "Sate Bu Aji",
    totalPrice: 30000,
    category: ["eatery"],
    paymentMethods: ["cash"],
    menus: [
      { name: "Sate ayam", price: 12000 },
      { name: "Nasi", price: 5000 },
      { name: "Lontong", price: 5000 },
    ],
    ourReview: { rating: { derby: 3.8, riska: 4 } },
  },
  {
    title: "Ayam Nampol",
    totalPrice: 40000,
    category: ["eatery"],
    menus: [
      { name: "Paket Nampol Ayam Goreng", price: 14000 },
      { name: "Lele Goreng", price: 13000 },
      { name: "Ayam Goreng", price: 13000 },
      { name: "Kepala Ayam (3)", price: 10000 },
      { name: "Plecing Kangkung", price: 9000 },
      { name: "Kulit Goreng", price: 4000 },
      { name: "Nasi Putih", price: 5000 },
      { name: "Es Teh", price: 5000 },
    ],
    detailInformation: {
      mapLocationUrl: "https://maps.app.goo.gl/Mo7ENbbgkrAWRwMw7",
    },
    ourReview: { rating: { derby: 4, riska: 4.2 } },
    lastVisit: "2024-09-28",
  },
  {
    title: "Geprek Zeno",
    totalPrice: 24000,
    category: ["eatery"],
    menus: [
      { name: "Paket ayam + nasi", price: 12000 },
      { name: "Usus", price: 5000 },
      { name: "Terong", price: 5000 },
    ],
    ourReview: { rating: { derby: 3.5, riska: 4.8 } },
  },
  {
    title: "Bakso Solo Tetelan Pak Kumis",
    totalPrice: 36000,
    category: ["eatery"],
    menus: [
      { name: "Bakso urat tetelan sapi", price: 15000 },
      { name: "Es teh", price: 3000 },
    ],
    ourReview: { rating: { derby: 4.5, riska: 4.5 } },
    lastVisit: "2024-08-18",
  },
  {
    title: "Mie Ayam Jakarta Pak Dirman",
    totalPrice: 40000,
    category: ["eatery"],
    paymentMethods: ["cash", "qr"],
    menus: [
      { name: "Mie ayam", price: 17000 },
      { name: "Es teh", price: 3000 },
    ],
    ourReview: { rating: { derby: 4.7, riska: 3.8 } },
  },
  {
    title: "TokTok! Mie",
    totalPrice: 53000,
    category: ["eatery"],
    menus: [
      { name: "Mie ayam komplit chili oil", price: 23000 },
      { name: "Nasi goreng ayam jumbo ori", price: 21000 },
      { name: "Es teh manis", price: 5000 },
    ],
    ourReview: { rating: { derby: 4, riska: 4.1 } },
  },
  {
    title: "Marugame Udon",
    totalPrice: 180000,
    category: ["eatery"],
    menus: [
      { name: "Beef Curry Udon", price: 62000 },
      { name: "Beef Carbonara Udon", price: 60000 },
      { name: "Toritama Ankake Udon", price: 60000 },
      { name: "Beef Croquette", price: 13000 },
      { name: "Chicken Katsu", price: 17000 },
      { name: "Chikuwa", price: 8000 },
      { name: "Egg Tempura", price: 14000 },
      { name: "Gyoza", price: 12000 },
      { name: "Kakkiage Tempura", price: 9000 },
      { name: "Nagoya Tebasaki", price: 17000 },
      { name: "Nori Tempura", price: 15000 },
      { name: "Skewered Shumai", price: 12000 },
      { name: "Satsuma Butter", price: 13000 },
      { name: "Cold Ocha", price: 14000 },
      { name: "Ice Lemon Tea", price: 14000 },
    ],
    ourReview: { rating: { derby: 4.8, riska: 4.8 } },
    lastVisit: "2024-08-18",
  },
  {
    title: "Jagogo Chicken",
    totalPrice: 16000,
    category: ["eatery"],
    paymentMethods: ["cash", "qr"],
    menus: [
      { name: "Ayam geprek", price: 8000 },
      { name: "Nasi ayam geprek", price: 10000 },
    ],
    ourReview: { rating: { derby: 3.8, riska: 4.5 } },
  },
  {
    title: "Bakso Solo Samrat",
    totalPrice: 116600,
    category: ["eatery"],
    additionalCost: { ppn: "10%" },
    paymentMethods: ["cash", "debit"],
    menus: [
      { name: "Bakso Rusuk Biasa", price: 67000 },
      { name: "Bakso Urat Granat", price: 45000 },
      { name: "Bakso Urat Rusuk", price: 67000 },
      { name: "Baso Isi Daging", price: 43000 },
      { name: "Bakso Tenis", price: 46000 },
      { name: "Es Teh Manis", price: 8000 },
      { name: "Es Teh Tawar", price: 7000 },
      { name: "Air Mineral", price: 8000 },
    ],
    ourReview: { rating: { derby: 4.8, riska: 4.9 } },
    lastVisit: "2024-09-29",
  },
  {
    title: "Warung LBT",
    totalPrice: 28000,
    category: ["eatery"],
    menus: [{ name: "Lontong balap", price: 14000 }],
    ourReview: { rating: { derby: 4.5, riska: 2 } },
    lastVisit: "2024-08-31",
  },
  {
    title: "Warung Mak Beng",
    totalPrice: 121000,
    category: ["eatery"],
    menus: [{ name: "Standart porsi", price: 60500 }],
    ourReview: { rating: { derby: 4.6, riska: 4.5 } },
  },
  {
    title: "Sate Barokah Renon",
    totalPrice: 30000,
    category: ["eatery"],
    menus: [
      { name: "Sate ayam", price: 10000 },
      { name: "Gule kambing", price: 10000 },
    ],
    ourReview: { rating: { derby: 3.9, riska: 4.5 } },
  },
  {
    title: "Nasi Kuning Bu Usman",
    totalPrice: 20000,
    category: ["eatery"],
    menus: [{ name: "Nasi kuning", price: 10000 }],
    ourReview: { rating: { derby: 4.5, riska: 4.1 } },
  },
  {
    title: "Soto Lestari",
    totalPrice: 52000,
    category: ["eatery"],
    menus: [
      { name: "Soto Kulit + Ati Ampela", price: 18000 },
      { name: "Soto Telur + Uritan", price: 16000 },
      { name: "Soto Telur Muda", price: 15000 },
      { name: "Nasi", price: 5000 },
      { name: "Es Teh", price: 4000 },
      { name: "Emping", price: 5000 },
    ],
    lastVisit: "2024-09-28",
    ourReview: { rating: { derby: 4.2, riska: 4.8 } },
  },
  {
    title: "Dadar Beredar",
    totalPrice: 72000,
    category: ["eatery"],
    menus: [
      {
        name: "Dadar bakar daging",
        price: 37000,
        addons: [{ name: "Nasi jeruk", price: 1500 }],
      },
      { name: "Dadar kulit", price: 23500 },

      { name: "Es teh", price: 5000 },
    ],
    ourReview: { rating: { derby: 4, riska: 4.4 } },
    lastVisit: "2024-09-14",
  },
  {
    title: "Ichiban Sushi",
    totalPrice: 186000,
    category: ["eatery"],
    additionalCost: { ppn: "10%", serviceCharge: "5%" },
    menus: [
      {
        name: "Chicken Curry Paithan ramen",
        price: 49000,
        addons: [{ name: "Tori Karaage", price: 8000 }],
      },

      { name: "Ohayo Platter", price: 65000 },
      { name: "Konnichiwa Platter", price: 80000 },
      { name: "Spicy Dry Ramen", price: 40000 },
      { name: "Dory Spicy Cheese Roll", price: 37000 },
      { name: "Tuna Mentai Roll", price: 38000 },
      { name: "Unagi Shrimp Roll", price: 59000 },
      { name: "Kani Mayo Inari", price: 24000 },
      { name: "Chizu Kaarage", price: 37000 },
      { name: "Miso Soup", price: 10000 },
      { name: "Beef Bomb", price: 15000 },
      { name: "Ocha", price: 13000 },
    ],
    ourReview: { rating: { derby: 4.5, riska: 4.6 } },
    lastVisit: "2024-10-10",
  },
  {
    title: "Pyeong Pyeong",
    totalPrice: 127600,
    category: ["eatery"],
    additionalCost: { ppn: "10%" },
    menus: [
      { name: "Dakjuk adult", price: 18000 },
      { name: "Spicy tonkotsu", price: 32000 },
      { name: "Dakbal", price: 28000 },
      { name: "Rose Tteokbokki", price: 28000 },
      { name: "Air mineral", price: 10000 },
    ],
    ourReview: { rating: { derby: 4.3, riska: 4.2 } },
  },
  {
    title: "Batu Karu Kopi",
    totalPrice: 232000,
    category: ["cafe"],
    additionalCost: { ppn: "11%", serviceCharge: "5%" },
    paymentMethods: ["debit", "cash", "qr"],
    menus: [
      { name: "Batukaru breakfast", price: 75000 },
      { name: "Roti bakar (chocolate cheese)", price: 50000 },
      { name: "Tea selection (tropical mango)", price: 35000 },
      { name: "Smoothies (banana cocoa)", price: 40000 },
    ],
    ourReview: { rating: { derby: 4.9, riska: 4.8 } },
  },
  {
    title: "Rumah Makan Uni Tati",
    totalPrice: 70000,
    category: ["eatery"],
    paymentMethods: ["cash", "qr"],
    menus: [
      { name: "Nasi padang rendang telur dadar", price: 30000 },
      { name: "Nasi padang rendang kikil", price: 35000 },
      { name: "Aqua", price: 5000 },
    ],
    ourReview: { rating: { derby: 2.3, riska: 4.4 } },
  },
  {
    title: "ACK",
    totalPrice: 36000,
    category: ["eatery"],
    menus: [{ name: "Paket aman B", price: 18000 }],
    ourReview: { rating: { derby: 4.5, riska: 4.5 } },
    paymentMethods: ["cash", "debit", "qr"],
  },
  {
    title: "Bakso Utomo",
    totalPrice: 47000,
    category: ["eatery"],
    menus: [
      { name: "Bakso besar + tulang iga", price: 25000 },
      { name: "Mie ayam + bakso", price: 14000 },
      { name: "Kerupuk", price: 1000 },
      { name: "Es teh manis", price: 4000 },
      { name: "Es teh tawar", price: 3000 },
    ],
    ourReview: { rating: { derby: 4.5, riska: 4.5 } },
  },
  {
    title: "Nasi Goreng SDN 6 Sesetan",
    totalPrice: 20000,
    category: ["eatery"],
    detailInformation: {
      mapLocationUrl: "https://maps.app.goo.gl/aMo49dm8VLsVdDoG9",
    },
    paymentMethods: ["cash"],
    menus: [
      { name: "Nasi goreng", price: 10000 },
      { name: "Mie goreng", price: 10000 },
    ],
    ourReview: { rating: { derby: 2, riska: 2.5 } },
  },
  {
    title: "Doughnut Lio",
    category: ["bakery"],
    totalPrice: 38000,
    detailInformation: {
      mapLocationUrl: "https://maps.app.goo.gl/FjPoz2qJpv9BGSJv5",
    },
    paymentMethods: ["cash", "qr"],
    menus: [
      { name: "Donut isi 6", price: 38000 },
      { name: "Bomboloni satuan", price: 12000 },
      { name: "Bolen pisang", price: 5000 },
    ],
    ourReview: { rating: { derby: 3.5, riska: 4.4 } },
  },
  {
    title: "Cak Kris",
    totalPrice: 57000,
    category: ["eatery"],
    detailInformation: {
      mapLocationUrl: "https://maps.app.goo.gl/eZ2wRcnYSESwSxPWA",
    },
    menus: [
      { name: "Soto ayam telur (nasi pisah)", price: 25000 },
      { name: "Nasi goreng spesial jeroan", price: 24000 },
      { name: "Nasi goreng jawa", price: 20000 },
      { name: "Air mineral", price: 4000 },
      { name: "Kerupuk", price: 2000 },
      { name: "Kerupuk udang", price: 2000 },
    ],
    ourReview: { rating: { derby: 4.6, riska: 4.7 } },
  },
  {
    title: "Waroeng Sambal Bakar Bali",
    totalPrice: 55000,
    category: ["eatery"],
    menus: [
      { name: "Usus ayam", price: 11000 },
      { name: "Tahu", price: 2000 },
      { name: "Mie bakar tembikar + telur", price: 24000 },
      { name: "Nasi putih", price: 5000 },
      { name: "Air mineral", price: 4000 },
      { name: "Es teh manis gentong", price: 6000 },
      { name: "Es teh tawar", price: 3000 },
    ],
    ourReview: { rating: { derby: 3.6, riska: 3 } },
  },
  {
    title: "Mie Pangsit Anugrah",
    totalPrice: 37000,
    category: ["eatery"],
    menus: [
      { name: "Mie pangsit", price: 16000 },
      { name: "Bakso goreng", price: 10000 },
      { name: "Pangsit goreng", price: 1000 },
      { name: "Air mineral", price: 5000 },
    ],
    ourReview: { rating: { derby: 4, riska: 4.4 } },
  },
  {
    title: "Lalapan Putri",
    totalPrice: 40000,
    category: ["eatery"],
    detailInformation: {
      mapLocationUrl: "https://maps.app.goo.gl/L5Bkjsx2BdF6c49B9",
    },
    menus: [
      { name: "Nasi lele", price: 13000 },
      { name: "Nasi ayam", price: 17000 },
      { name: "Sate ati ampela", price: 2000 },
      { name: "Es teh", price: 3000 },
    ],
    ourReview: { rating: { derby: 4.5, riska: 4.6 } },
  },
  {
    title: "Shofuku Ramen Bar",
    totalPrice: 233000,
    category: ["eatery"],
    additionalCost: { ppn: "10%", serviceCharge: "5%" },
    menus: [
      { name: "Assari shio ramen (reguler)", price: 66000 },
      { name: "Fire miso ramen (reguler)", price: 67000 },
      { name: "Chicken kaarage nanban", price: 38000 },
      { name: "Ice tea (refill)", price: 15000 },
      { name: "Ice ocha (refill)", price: 15000 },
    ],
    ourReview: { rating: { derby: 4.5, riska: 4.8 } },
  },
  {
    title: "Warung 8 Kari Ayam Pedas",
    totalPrice: 50000,
    category: ["eatery"],
    paymentMethods: ["cash"],
    menus: [
      { name: "Nasi Kari ayam", price: 18000 },
      { name: "Nasi", price: 3000 },
      { name: "Bakwan jagung", price: 1000 },
      { name: "Perkedel", price: 1000 },
      { name: "Tempe", price: 1000 },
      { name: "Telur", price: 5000 },
      { name: "Kerupuk", price: 1000 },
      { name: "Es Teh", price: 4000 },
      { name: "Air mineral", price: 4000 },
    ],
    ourReview: { rating: { derby: 4.8, riska: 4.9 } },
    lastVisit: "2024-09-22",
  },
  {
    title: "Nasi Pedas Bu Andika",
    totalPrice: 90000,
    category: ["eatery"],
    menus: [
      { name: "Nasi campur (kentang, telur, tempe)", price: 35000 },
      { name: "Nasi campur (ayam, tempe, usus, nangka)", price: 43000 },
      { name: "Es teh tawar", price: 5000 },
      { name: "Air mineral", price: 5000 },
    ],
    ourReview: { rating: { derby: 4, riska: 2 } },
  },
  {
    title: "Bocil Esteroos",
    totalPrice: 40000,
    category: ["eatery"],
    menus: [
      { name: "Bakso ayam", price: 12000 },
      {
        name: "Indomie ramen",
        price: 10000,
        addons: [{ name: "Extra telur", price: 5000 }],
      },

      { name: "Es gosrok", price: 5000 },
      { name: "Melon gosrok", price: 8000 },
    ],
    ourReview: { rating: { derby: 3.9, riska: 4.3 } },
  },
  {
    title: "Warung Mamie Ada",
    totalPrice: 80000,
    category: ["eatery"],
    menus: [
      {
        name: "Soto betawi campur",
        price: 24000,
        addons: [{ name: "Extra risol", price: 3000 }],
      },
      { name: "Nasi putih", price: 5000 },
      { name: "Risoles ragout", price: 4000 },
      { name: "Es teh", price: 4000 },
    ],
    ourReview: { rating: { derby: 4.9, riska: 4.6 } },
  },
  {
    title: "Mie Gacoan",
    totalPrice: 53000,
    category: ["eatery"],
    menus: [
      { name: "Mie gacoan lv 2", price: 10459 },
      { name: "Udang keju", price: 9549 },
      { name: "Lumpia udang", price: 9549 },
      { name: "Tea", price: 4099 },
      { name: "Air mineral botol", price: 4099 },
    ],
    additionalCost: { ppn: "10%" },
    ourReview: { rating: { derby: 4, riska: 4.7 } },
    lastVisit: "2024-09-21",
  },
  {
    title: "MADCOCO",
    totalPrice: 32000,
    category: ["beverage"],
    menus: [{ name: "Original Coconut Shake", price: 32000 }],
    ourReview: { rating: { derby: 4, riska: 4 } },
  },
  {
    title: "Orlenalycious",
    totalPrice: 58000,
    category: ["bakery"],
    menus: [
      { name: "Nutella Cromboloni", price: 32000 },
      { name: "Matcha Cheese Croissant", price: 28000 },
    ],
    ourReview: { rating: { derby: 3, riska: 4 } },
  },
  {
    title: "Sate Padang Ajo Midun Pariaman",
    totalPrice: 57000,
    category: ["eatery"],
    menus: [
      { name: "Sate Padang Lontong", price: 19000 },
      { name: "Keripik Ubi", price: 3000 },
      { name: "Kerupuk Rambak", price: 3000 },
      { name: "Es Teh", price: 5000 },
    ],
    ourReview: { rating: { derby: 4.8, riska: 4.8 } },
    lastVisit: "2024-09-24",
  },
  {
    title: "Ayam Kampung & Rawon Bu Ros",
    totalPrice: 117000,
    category: ["eatery"],
    menus: [
      { name: "Ayam Goreng Kampung (Dada)", price: 32500 },
      { name: "Soto Campur (Daging Ayam + Ceker)", price: 25000 },
      { name: "Rawon Campur (Daging + Tetelan)", price: 35000 },
      { name: "Usus Goreng", price: 10000 },
      { name: "Ati Ampela Goreng", price: 7500 },
      { name: "Kepala Ayam (Isi 3)", price: 10000 },
      { name: "Extra Sambal", price: 3500 },
      { name: "Nasi Putih", price: 7000 },
      { name: "Es Teh Tawar", price: 6000 },
      { name: "Es Teh Manis", price: 8500 },
      { name: "Sop Durian", price: 35000 },
    ],
    additionalCost: { ppn: "10%" },
    ourReview: { rating: { derby: 4.9, riska: 4.8 } },
    lastVisit: "2024-08-17",
  },
  {
    title: "Mie Ayam Rock n Roll",
    totalPrice: 69000,
    category: ["eatery"],
    menus: [
      { name: "Mie Lebar Jumbo Yamin", price: 18000 },
      {
        name: "Mie Yamien Jamur",
        price: 20000,
        addons: [{ name: "Ceker Sepasang", price: 4000 }],
      },
      { name: "Pangsit Goreng", price: 10000 },
      { name: "Kepala Ayam (Isi 3)", price: 10000 },
      { name: "Es Teh Tawar", price: 4000 },
      { name: "Es Teh Manis", price: 6000 },
    ],
    additionalCost: { ppn: "10%" },
    paymentMethods: ["cash", "qr"],
    ourReview: { rating: { derby: 4.5, riska: 4.7 } },
  },
  {
    title: "Warung Bunana",
    totalPrice: 81500,
    category: ["eatery"],
    menus: [
      { name: "Canai Telur (L)", price: 15000 },
      { name: "Canai Susu (S)", price: 7000 },
      { name: "Canai Susu Keju (S)", price: 13500 },
      { name: "Martabak India (S)", price: 11500 },
      { name: "Teh Tarik (Ice)", price: 12500 },
      { name: "Mango Lassi", price: 22000 },
    ],
    paymentMethods: ["cash", "qr"],
    ourReview: { rating: { derby: 4.1, riska: 4.5 } },
  },
  {
    title: "Warung Muslim Sederhana",
    totalPrice: 24000,
    category: ["eatery"],
    menus: [
      { name: "Nasi Telor Tahu Sayur Bayam", price: 8000 },
      { name: "Nasi Tongkol Tahu Sayur Labu", price: 11000 },
      { name: "Kerupuk", price: 2000 },
      { name: "Air Mineral", price: 3000 },
    ],
    paymentMethods: ["cash"],
    detailInformation: {
      mapLocationUrl: "https://maps.app.goo.gl/w8D2AHMSFvJdZ3qt5",
    },
    ourReview: { rating: { derby: 3.4, riska: 4.1 } },
  },
  {
    title: "Bakso Soneta Blitar",
    totalPrice: 37000,
    category: ["eatery"],
    menus: [
      { name: "Bakso Mercon", price: 15000 },
      { name: "Bakso Sapi Campur", price: 15000 },
      { name: "Lontong", price: 2000 },
      { name: "Pangsit", price: 1000 },
      { name: "Air Mineral", price: 2000 },
    ],
    paymentMethods: ["cash"],
    detailInformation: {
      mapLocationUrl: "https://maps.app.goo.gl/XPNqbwysVufyiXHf7",
    },
    ourReview: { rating: { derby: 4.7, riska: 4.2 } },
  },
  {
    title: "Lalapan Cak Yanto",
    totalPrice: 44000,
    category: ["eatery"],
    menus: [
      { name: "Ayam Goreng", price: 13000 },
      { name: "Lele Goreng", price: 9000 },
      { name: "Usus", price: 5000 },
      { name: "Ati Ampela", price: 6000 },
      { name: "Nasi Putih", price: 5000 },
      { name: "Kol Goreng", price: 0 },
      { name: "Es Teh", price: 4000 },
      { name: "Es Teh Tawar", price: 2000 },
    ],
    paymentMethods: ["cash"],
    detailInformation: {
      mapLocationUrl: "https://maps.app.goo.gl/TUBLuMCxxC4N1QbJ6",
    },
    ourReview: { rating: { derby: 4, riska: 4.4 } },
  },
  {
    title: "Warung Penyetan Ayam Sambel Bawang Bu Sri",
    totalPrice: 62000,
    category: ["eatery"],
    menus: [
      { name: "Telur + Tempe Penyet", price: 8000 },
      { name: "Lele Penyet", price: 12000 },
      { name: "Bakso Ayam Penyet", price: 13000 },
      { name: "Nasi Uduk", price: 6000 },
      { name: "Kol Goreng", price: 3000 },
      { name: "Kepala Ayam Goreng", price: 6000 },
    ],
    paymentMethods: ["cash", "qr"],
    ourReview: { rating: { derby: 4.5, riska: 4.3 } },
  },
  {
    title: "Warung Parasari",
    totalPrice: 81000,
    category: ["eatery"],
    menus: [
      { name: "Rawon Balung Sapi + Daging", price: 30000 },
      { name: "Balung Sapi Spesial Pedas", price: 30000 },
      { name: "Nasi", price: 5000 },
      { name: "Es Teh", price: 5000 },
      { name: "Es Gula", price: 5000 },
      { name: "Kerupuk", price: 1000 },
    ],
    paymentMethods: ["cash"],
    detailInformation: {
      mapLocationUrl: "https://maps.app.goo.gl/zDjURy6XVy63get38",
    },
    ourReview: { rating: { derby: 4.6, riska: 3 } },
  },
  {
    title: "Mina Sari Murti",
    totalPrice: 105000,
    category: ["eatery"],
    menus: [
      {
        name: "Paket II A",
        price: 90000,
        addons: [
          { name: "Gurami Kesyong Jumbo 1 Ekor", price: 0 },
          { name: "Plecing Kangkung", price: 0 },
          { name: "Nasi Putih 1 Bakul", price: 0 },
        ],
      },
      { name: "Kentang Goreng", price: 20000 },
      { name: "Es Teh", price: 5000 },
      { name: "Es Jeruk", price: 8000 },
      { name: "Es Soda Gembira", price: 16000 },
      { name: "Kopi Gingseng", price: 10000 },
    ],
    paymentMethods: ["cash", "qr"],
    detailInformation: {
      mapLocationUrl: "https://maps.app.goo.gl/ZEBFd3tsfKBGNikP8",
    },
    ourReview: { rating: { derby: 4.8, riska: 4.6 } },
    lastVisit: "2024-09-24",
  },
  {
    title: "Sour Sally",
    totalPrice: 69000,
    category: ["beverage", "dessert"],
    menus: [{ name: "Black Sakura [L]", price: 69000 }],
    ourReview: { rating: { derby: 4.2, riska: 4.5 } },
    lastVisit: "2024-02-16",
  },
  {
    title: "Waroeng Steak & Shake",
    totalPrice: 123000,
    category: ["eatery"],
    menus: [
      { name: "Paket Chicko", price: 25454 },
      { name: "Chicken Double", price: 33636 },
      { name: "Tenderloin Double", price: 41364 },
      { name: "Drum n' Chips", price: 23636 },
      { name: "Teh Tawar", price: 8181 },
      { name: "Milkshake Vanilla", price: 20909 },
      { name: "Iced Honey Lime", price: 18182 },
    ],
    additionalCost: { ppn: "10%" },
    ourReview: { rating: { derby: 4.3, riska: 4.5 } },
    lastVisit: "2024-06-19",
  },
  {
    title: "Soerabi Bandung Sudirman",
    totalPrice: 51000,
    category: ["eatery"],
    menus: [
      { name: "Soerabi Vanilla", price: 12000 },
      { name: "Soerabi Keju", price: 13000 },
      { name: "Bakso Bakar", price: 10000 },
      { name: "Teh Tawar", price: 4000 },
      { name: "STMJ", price: 12000 },
    ],
    ourReview: { rating: { derby: 3.7, riska: 4.2 } },
  },
  {
    title: "Ramen 1",
    totalPrice: 124000,
    category: ["eatery"],
    menus: [
      { name: "Seafood Nanban Greget", price: 27000 },
      { name: "Ramen Chicken BBQ Roll Mala ", price: 29800 },
      { name: "Tsukumen Seafood Tempura", price: 29800 },
      { name: "Ocha Ice", price: 19600 },
    ],
    additionalCost: { serviceCharge: "5.5%", ppn: "10%" },
    ourReview: { rating: { derby: 4, riska: 4.4 } },
  },
  {
    title: "Soto Ayam Elza",
    totalPrice: 33000,
    category: ["eatery"],
    menus: [
      { name: "Soto Spesial", price: 20000 },
      { name: "Soto Ayam + Ceker", price: 13000 },
    ],
    ourReview: { rating: { derby: 4, riska: 4.4 } },
  },
  {
    title: "Warung Kuah Pindang",
    totalPrice: 47000,
    category: ["eatery"],
    menus: [
      { name: "Tipat Kare Spesial", price: 20000 },
      { name: "Risol Mayo", price: 18000 },
      { name: "Es Gula", price: 3000 },
      { name: "Es Teh", price: 4000 },
    ],
    detailInformation: {
      mapLocationUrl: "https://maps.app.goo.gl/t7d1raHaJgqgLw5c6",
    },
    ourReview: { rating: { derby: 4.5, riska: 4.7 } },
    lastVisit: "2024-10-20",
  },
  {
    title: "Seblak Teh Senny",
    totalPrice: 44000,
    category: ["eatery"],
    menus: [
      {
        name: "Seblak Prasmanan",
        price: 17000,
        addons: [
          { name: "Kerupuk", price: 3000 },
          { name: "Sawi", price: 1000 },
          { name: "Crab Stick", price: 3000 },
          { name: "Dumpling Ayam", price: 3000 },
          { name: "Scallop", price: 3000 },
        ],
      },
    ],
    ourReview: { rating: { derby: 4.5, riska: 3.5 } },
    lastVisit: "2024-01-13",
  },
  {
    title: "POCI MOCI",
    totalPrice: 9000,
    category: ["beverage"],
    menus: [
      { name: "Es Teh Poci Original", price: 3000 },
      { name: "Extrajoss Susu", price: 6000 },
    ],
    detailInformation: {
      mapLocationUrl: "https://maps.app.goo.gl/Ktq4XeVjbMg8ywRs6",
    },
    ourReview: { rating: { derby: 3, riska: 4 } },
  },
  {
    title: "Kedai Sutan",
    totalPrice: 45000,
    category: ["eatery"],
    menus: [
      { name: "Ketupat Padang", price: 17000 },
      { name: "Sala Lauak (isi 3)", price: 2000 },
      { name: "Air Mineral", price: 5000 },
    ],
    detailInformation: {
      mapLocationUrl: "https://maps.app.goo.gl/wgnPK9B82c7gH72F7",
    },
    ourReview: { rating: { derby: 4.4, riska: 4.5 } },
    lastVisit: "2024-01-13",
  },
  {
    title: "Warung Makan Nasi Goreng Level Pedas",
    totalPrice: 34000,
    category: ["eatery"],
    menus: [
      { name: "Nasi Goreng Kambing", price: 15000 },
      { name: "Nasi Goreng Mawut Ayam", price: 15000 },
      { name: "Es Teh", price: 4000 },
    ],
    detailInformation: {
      mapLocationUrl: "https://maps.app.goo.gl/gjhMw8QQ77xWNhWQA",
    },
    paymentMethods: ["cash"],
    ourReview: { rating: { derby: 2.1, riska: 3 } },
    lastVisit: "2024-01-14",
  },
  {
    title: "Soto Ayam dan Nasi Goreng Nikmat",
    totalPrice: 65000,
    category: ["eatery"],
    menus: [
      { name: "Soto Ayam", price: 20000 },
      { name: "Nasi", price: 5000 },
      { name: "Es Teh", price: 5000 },
      { name: "Air Mineral", price: 5000 },
      { name: "Kerupuk Udang", price: 5000 },
    ],
    detailInformation: {
      mapLocationUrl: "https://maps.app.goo.gl/KkjMzujkoqhu44Ke6",
    },
    paymentMethods: ["cash", "qr"],
    ourReview: { rating: { derby: 3, riska: 3.5 } },
  },
  {
    title: "KIM Artisan Bakery",
    totalPrice: 63000,
    category: ["bakery"],
    menus: [
      { name: "Cromboloni Strawberry Pasta", price: 18000 },
      { name: "Cromboloni Nutella", price: 30000 },
      { name: "Cream Puff", price: 15000 },
      { name: "Thailand Milk Bun", price: 18000 },
    ],
    detailInformation: {
      mapLocationUrl: "https://maps.app.goo.gl/kJcW9yvrgLhwVjG96",
    },
    paymentMethods: ["cash", "qr"],
    ourReview: { rating: { derby: 4.5, riska: 4.5 } },
  },
  {
    title: "Zeribowl",
    totalPrice: 144000,
    category: ["dessert", "snack"],
    menus: [
      { name: "Sunny Side Up", price: 45000 },
      { name: "Happy Vanilla", price: 46000 },
      { name: "Taiwan Crispy Chicken BBQ", price: 53000 },
    ],
    ourReview: { rating: { derby: 4, riska: 4.2 } },
    lastVisit: "2024-01-21",
  },
  {
    title: "Koneo",
    totalPrice: 60000,
    category: ["dessert"],
    menus: [
      { name: "Matcha Bingsoo", price: 30000 },
      { name: "Mango Bingsoo", price: 30000 },
    ],
    ourReview: { rating: { derby: 4.4, riska: 4.5 } },
    lastVisit: "2024-09-01",
  },
  {
    title: "Mie Pangsit Vonny Jakarta",
    totalPrice: 44000,
    category: ["eatery"],
    menus: [
      { name: "Mie Pangsit Ayam", price: 15000 },
      { name: "Mie Pangsit Ayam Baso Sapi", price: 20000 },
      { name: "Es Teh Manis", price: 5000 },
      { name: "Es Teh Tawar", price: 4000 },
    ],
    ourReview: { rating: { derby: 3, riska: 4 } },
    lastVisit: "2024-01-18",
  },
  {
    title: "Solaria",
    totalPrice: 79000,
    category: ["eatery"],
    menus: [
      { name: "Express Bowl Ayam Mayo", price: 24546 },
      { name: "Nasi + Crispy Chicken Spicy Mayo", price: 30000 },
      { name: "Es Teh Manis", price: 9002 },
      { name: "Es Teh Tawar", price: 8183 },
    ],
    ourReview: { rating: { derby: 4, riska: 4.5 } },
    lastVisit: "2024-08-24",
  },
  {
    title: "Bakso Pak KUMIS",
    totalPrice: 51000,
    category: ["eatery"],
    menus: [
      {
        name: "Bakso Lengkap",
        price: 15000,
        addons: [{ name: "Tetelan", price: 5000 }],
      },
      { name: "Kerupuk Pangsit (isi 3)", price: 2000 },
      { name: "Es Teh Manis", price: 5000 },
      { name: "Air Mineral Dingin", price: 4000 },
    ],
    ourReview: { rating: { derby: 4, riska: 4.4 } },
    lastVisit: "2024-01-28",
    detailInformation: {
      mapLocationUrl: "https://maps.app.goo.gl/Kjyr1h7jMTZjWvQ76",
    },
  },
  {
    title: "Bebek Patmoroso",
    totalPrice: 91000,
    category: ["eatery"],
    menus: [
      { name: "Bebek Goreng", price: 31000 },
      { name: "Kol Goreng", price: 8000 },
      { name: "Nasi Putih", price: 6000 },
      { name: "Es Teh Manis", price: 5000 },
      { name: "Es Teh Tawar", price: 4000 },
    ],
    ourReview: { rating: { derby: 3.7, riska: 4.4 } },
    lastVisit: "2024-01-27",
  },
  {
    title: "Pempek Perdana",
    totalPrice: 30000,
    category: ["eatery"],
    menus: [{ name: "Pempek Campur", price: 15000 }],
    ourReview: { rating: { derby: 3, riska: 2.5 } },
    lastVisit: "2024-01-22",
  },
  {
    title: "Nasi Goreng Chef Wit",
    totalPrice: 32000,
    category: ["eatery"],
    menus: [
      { name: "Nasi Goreng Mawut Bakar", price: 15000 },
      { name: "Nasi Goreng Gila Bakar", price: 17000 },
    ],
    ourReview: { rating: { derby: 4, riska: 4.3 } },
    lastVisit: "2024-01-29",
    detailInformation: {
      mapLocationUrl: "https://maps.app.goo.gl/PWgyYe3M2h29snpH9",
    },
  },
  {
    title: "Warmindo Bang Juned 22",
    totalPrice: 47000,
    category: ["eatery"],
    menus: [
      { name: "Mie Bangladesh", price: 18000 },
      { name: "Es Teh", price: 5000 },
    ],
    ourReview: { rating: { derby: 3.6, riska: 4 } },
    lastVisit: "2024-01-30",
    paymentMethods: ["cash", "qr"],
    additionalCost: { serviceCharge: "1%" },
  },
  {
    title: "Martabak Gembrudug",
    totalPrice: 32000,
    category: ["snack"],
    menus: [{ name: "Martabak Telor Istimewa", price: 32000 }],
    ourReview: { rating: { derby: 3.6, riska: 4 } },
    lastVisit: "2024-01-30",
    paymentMethods: ["cash"],
    detailInformation: {
      mapLocationUrl: "https://maps.app.goo.gl/2Mii89F85eehWew38",
    },
  },
  {
    title: "Soto Dobrak",
    totalPrice: 48000,
    category: ["eatery"],
    menus: [
      {
        name: "Soto Ayam",
        price: 18000,
        addons: [{ name: "Nasi Putih", price: 3000 }],
      },
      { name: "Ayam Geprek + Nasi", price: 22000 },
    ],
    ourReview: { rating: { derby: 3.8, riska: 3 } },
    lastVisit: "2024-02-01",
  },
  {
    title: "Warung 98",
    totalPrice: 36000,
    category: ["eatery"],
    menus: [
      { name: "Tahu Tek + Telur", price: 14000 },
      { name: "Tahu Campur", price: 14000 },
      { name: "Es Gula", price: 4000 },
      { name: "Air Mineral", price: 4000 },
    ],
    ourReview: { rating: { derby: 3.8, riska: 4.5 } },
    detailInformation: {
      mapLocationUrl: "https://maps.app.goo.gl/gyjcwDFCoEG2bykj8",
    },
    lastVisit: "2024-02-02",
  },
  {
    title: "JFC",
    totalPrice: 34000,
    category: ["eatery"],
    menus: [
      { name: "Pajay 2", price: 15500 },
      { name: "Geprek Pajay 2", price: 18500 },
    ],
    ourReview: { rating: { derby: 4, riska: 3.5 } },
    lastVisit: "2024-02-03",
  },
  {
    title: "Pangsit Cwie Mie Ayam",
    totalPrice: 58000,
    category: ["eatery"],
    menus: [
      { name: "Pangsit Cwie Mie Ayam", price: 14000 },
      { name: "Pangsit Cwie Mie Bakso", price: 19000 },
      { name: "Pangsit Goreng", price: 13000 },
      { name: "Es Soda Gembira", price: 9000 },
      { name: "Es Teh", price: 3000 },
    ],
    ourReview: { rating: { derby: 2.1, riska: 3 } },
    detailInformation: {
      mapLocationUrl: "https://maps.app.goo.gl/sgSqNCLsWged23Ak9",
    },
    lastVisit: "2024-02-03",
  },
  {
    title: "RM Padang Bungo Padi",
    totalPrice: 62000,
    category: ["eatery"],
    menus: [
      { name: "Nasi Cincang + Telur Dadar", price: 25000 },
      { name: "Nasi Rendang + Telur Dadar", price: 27000 },
      { name: "Es Teh", price: 5000 },
    ],
    ourReview: { rating: { derby: 4.2, riska: 4.5 } },
    detailInformation: {
      mapLocationUrl: "https://maps.app.goo.gl/PMNLmihMog5WFus26",
    },
    lastVisit: "2024-02-04",
  },
  {
    title: "Bebek Cak Kam",
    totalPrice: 96800,
    category: ["eatery"],
    menus: [
      { name: "Bebek XL + Nasi", price: 40000 },
      { name: "Es Teh", price: 4000 },
    ],
    additionalCost: { ppn: "10%" },
    ourReview: { rating: { derby: 4, riska: 4.5 } },
    lastVisit: "2024-02-04",
  },
  {
    title: "Gultik Sewu Kuto",
    totalPrice: 35000,
    category: ["eatery"],
    menus: [
      { name: "Nasi Disiram Gule Sapi", price: 10000 },
      {
        name: "Paket 1",
        price: 25000,
        addons: [
          { name: "Nasi", price: 0 },
          { name: "Gule Sapi", price: 0 },
          { name: "Sate Ayam (5 Tusuk)", price: 0 },
          { name: "Es Teh", price: 0 },
        ],
      },
    ],
    detailInformation: {
      mapLocationUrl: "https://maps.app.goo.gl/XQ1apWSweU2enhmdA",
    },
    ourReview: { rating: { derby: 4, riska: 4.0 } },
    lastVisit: "2024-02-06",
  },
  {
    title: "Mie Lontong Tahu Badrun",
    totalPrice: 30000,
    category: ["eatery"],
    menus: [
      { name: "Mie Komplit", price: 13000 },
      { name: "Es Teh", price: 4000 },
    ],
    ourReview: { rating: { derby: 4.5, riska: 4.0 } },
    lastVisit: "2024-08-29",
  },
  {
    title: "Warung Rujak Bu Wahyu",
    totalPrice: 43000,
    category: ["eatery"],
    menus: [
      { name: "Tipat Kuah", price: 12000 },
      { name: "Tipat Cantok", price: 8000 },
      { name: "Es Daluman", price: 6000 },
      { name: "Air Mineral", price: 5000 },
    ],
    detailInformation: {
      mapLocationUrl: "https://maps.app.goo.gl/49jm93MFC5BoN4tr9",
    },
    ourReview: { rating: { derby: 4.3, riska: 4.2 } },
    lastVisit: "2024-02-09",
  },
  {
    title: "Bakso Papaka",
    totalPrice: 42000,
    category: ["eatery"],
    menus: [
      { name: "Bakso Sapi Urat Besar", price: 15000 },
      { name: "Indomie Bakso Urat Besar", price: 17000 },
      { name: "Es Teh", price: 5000 },
      { name: "Air Mineral", price: 5000 },
    ],
    ourReview: { rating: { derby: 4.4, riska: 4.0 } },
    lastVisit: "2024-02-09",
  },
  {
    title: "Warung Yogyakarta",
    totalPrice: 83000,
    category: ["eatery"],
    menus: [
      { name: "Bebek Goreng Kalasan", price: 30000 },
      { name: "Nasi", price: 6000 },
      { name: "Es Teh", price: 5000 },
      { name: "Air Mineral", price: 6000 },
    ],
    ourReview: { rating: { derby: 4.7, riska: 4.7 } },
    lastVisit: "2024-02-10",
  },
  {
    title: "Shigeru",
    totalPrice: 124300,
    category: ["snack"],
    menus: [
      { name: "Spicy Odeng", price: 10000 },
      { name: "Sesame Chicken Katsu", price: 11000 },
      { name: "Chicken Katsu", price: 11000 },
      { name: "Calamari Katsu", price: 11000 },
      { name: "Roba Kawa", price: 10000 },
      { name: "Ayame Set", price: 62000 },
      { name: "Odeng Bakso Manis", price: 10000 },
      { name: "Salmon Fish Cake", price: 9000 },
      { name: "Air Mineral", price: 8000 },
    ],
    additionalCost: { ppn: "10%" },
    ourReview: { rating: { derby: 4.2, riska: 4.5 } },
    lastVisit: "2024-02-08",
  },
  {
    title: "IKEA Food",
    totalPrice: 124300,
    category: ["eatery"],
    menus: [
      { name: "Chicken Cordon Bleu", price: 50000 },
      { name: "Soft Drink (free refill)", price: 10000 },
      { name: "Soft Ice Cream", price: 11000 },
    ],
    ourReview: { rating: { derby: 4, riska: 4.4 } },
    lastVisit: "2024-02-08",
  },
  {
    title: "Keliki Coffee",
    totalPrice: 85000,
    category: ["cafe"],
    menus: [
      { name: "Es Kopi Keliki", price: 25000 },
      { name: "Strawberry Milk", price: 25000 },
      { name: "Almond Croissant", price: 35000 },
    ],
    detailInformation: {
      mapLocationUrl: "https://maps.app.goo.gl/QzVqsEqCyzSWW8dc6",
    },
    ourReview: { rating: { derby: 3.2, riska: 4.0 } },
    lastVisit: "2024-02-08",
  },
  {
    title: "Gococo",
    totalPrice: 62700,
    category: ["beverage"],
    menus: [
      {
        name: "Coco Shake",
        price: 20000,
        addons: [{ name: "Upsize (R)", price: 5000 }],
      },
      {
        name: "Mango Coco Shake",
        price: 27000,
        addons: [{ name: "Upsize (R)", price: 5000 }],
      },
      {
        name: "Oreo Coco Shake",
        price: 20000,
        addons: [{ name: "Upsize (R)", price: 5000 }],
      },
      {
        name: "Durian Coco Shake",
        price: 30000,
        addons: [{ name: "Upsize (R)", price: 5000 }],
      },
    ],
    additionalCost: { ppn: "10%" },
    ourReview: { rating: { derby: 4.5, riska: 4.7 } },
    lastVisit: "2024-09-04",
  },
  {
    title: "Ramenhead.",
    totalPrice: 132825,
    category: ["eatery"],
    menus: [
      { name: "Curry Ramen", price: 27500 },
      { name: "Niwatori No Hone Ramen", price: 27500 },
      { name: "Chicken", price: 11000 },
      { name: "Katsu", price: 11000 },
      { name: "Torichiizu", price: 16500 },
      { name: "Ocha", price: 11000 },
    ],
    additionalCost: { ppn: "10%", serviceCharge: "5%" },
    ourReview: { rating: { derby: 4.4, riska: 4.7 } },
    lastVisit: "2024-02-12",
  },
  {
    title: "Bread Basket",
    totalPrice: 100100,
    category: ["bakery"],
    menus: [
      { name: "White Sourdough", price: 34000 },
      { name: "Pretzel Danish", price: 22000 },
      { name: "Biscoff Cromboloni", price: 35000 },
    ],
    additionalCost: { ppn: "10%" },
    ourReview: { rating: { derby: 4.5, riska: 4.4 } },
    lastVisit: "2024-02-11",
  },
  {
    title: "Auntie Anne's",
    totalPrice: 68000,
    category: ["bakery", "snack"],
    menus: [
      { name: "Stix Eclairs", price: 29000 },
      { name: "Cheese Galore", price: 39000 },
    ],
    ourReview: { rating: { derby: 4, riska: 4.4 } },
    lastVisit: "2024-02-10",
  },
  {
    title: "Bukan Bubur Biasa",
    totalPrice: 33000,
    category: ["eatery"],
    menus: [
      { name: "Bubur Ayam", price: 14000 },
      { name: "Sate Telur Puyuh", price: 5000 },
    ],
    ourReview: { rating: { derby: 4, riska: 3.0 } },
    lastVisit: "2024-02-10",
  },
  {
    title: "Lalapan Anugrah 99",
    totalPrice: 52000,
    category: ["eatery"],
    menus: [
      { name: "Nasi Ayam Goreng", price: 20000 },
      { name: "Nasi Lele Goreng", price: 17000 },
      { name: "Tempe Goreng", price: 7000 },
      { name: "Es Teh", price: 4000 },
    ],
    ourReview: { rating: { derby: 4, riska: 4.5 } },
    lastVisit: "2024-02-14",
  },
  {
    title: "Go! Go! CURRY",
    totalPrice: 145000,
    category: ["eatery"],
    menus: [
      { name: "Grand Slam (M)", price: 115000 },
      { name: "Air Mineral", price: 15000 },
    ],
    ourReview: { rating: { derby: 4.5, riska: 4.6 } },
    lastVisit: "2024-02-14",
  },
  {
    title: "Bubur Ayam Mas Riyan",
    totalPrice: 46000,
    category: ["eatery"],
    paymentMethods: ["cash", "qr"],
    menus: [
      { name: "Bubur Ayam Polos", price: 10000 },
      { name: "Bubur Ayam Spesial Komplit", price: 23000 },
    ],
    ourReview: { rating: { derby: 4.5, riska: 4.6 } },
    lastVisit: "2024-02-17",
  },
  {
    title: "Delicious and Cheap",
    totalPrice: 32000,
    category: ["eatery"],
    menus: [{ name: "Spicy 2", price: 16000 }],
    ourReview: { rating: { derby: 3.8, riska: 3.8 } },
    lastVisit: "2024-02-17",
  },
  {
    title: "SNAPBINGSOO",
    totalPrice: 85000,
    category: ["dessert", "snack"],
    menus: [
      { name: "Tutti Fruity", price: 50000 },
      { name: "Mix Platter", price: 35000 },
    ],
    ourReview: { rating: { derby: 4.5, riska: 4.5 } },
    lastVisit: "2024-02-17",
  },
  {
    title: "Gado Gado Surabaya Mas Agus",
    totalPrice: 24000,
    category: ["eatery"],
    menus: [{ name: "Gado Gado", price: 12000 }],
    ourReview: { rating: { derby: 4.2, riska: 4.4 } },
    lastVisit: "2024-02-18",
  },
  {
    title: "Monsieur Spoon",
    totalPrice: 216700,
    category: ["cafe", "bakery"],
    menus: [
      { name: "Cheese Croissant", price: 37000 },
      { name: "Buttermilk Chicken Bun Croissant", price: 69000 },
      { name: "Pistachio Cromboloni", price: 40000 },
      { name: "Summer Paradise", price: 49000 },
      { name: "Berrybiscous", price: 39000 },
    ],
    additionalCost: { ppn: "10%" },
    ourReview: { rating: { derby: 4.6, riska: 4.6 } },
    lastVisit: "2024-06-19",
    detailInformation: {
      mapLocationUrl: "https://maps.app.goo.gl/BVvoZrJCZ4bMmATX8",
    },
  },
  {
    title: "Five Monkeys",
    totalPrice: 126000,
    category: ["eatery"],
    menus: [
      { name: "BBQ Bacon Cheeseburger (Single)", price: 53182 },
      { name: "Mineral Water", price: 8182 },
    ],
    additionalCost: { ppn: "10%" },
    ourReview: { rating: { derby: 4.5, riska: 4.7 } },
    lastVisit: "2024-02-18",
  },
  {
    title: "Kebuli Ijab Qabul",
    totalPrice: 99000,
    category: ["eatery"],
    menus: [
      { name: "Nasi Kebuli Ayam Besar", price: 35000 },
      { name: "Teh Arab", price: 10000 },
      { name: "Bir Pletok", price: 10000 },
    ],
    additionalCost: { ppn: "10%" },
    ourReview: { rating: { derby: 4.7, riska: 4.7 } },
    lastVisit: "2024-02-19",
  },
  {
    title: "Kedai 007",
    totalPrice: 41000,
    category: ["eatery"],
    menus: [
      { name: "Paket Ayam Kremes", price: 18000 },
      { name: "Air Mineral", price: 5000 },
    ],
    ourReview: { rating: { derby: 3.4, riska: 3.8 } },
    lastVisit: "2024-02-20",
  },
  {
    title: "Dr. Lontong, Sp.PL",
    totalPrice: 50000,
    category: ["eatery"],
    menus: [
      { name: "Lontong Medan", price: 21000 },
      { name: "Air Mineral", price: 4000 },
      { name: "Es Teh", price: 4000 },
    ],
    ourReview: { rating: { derby: 4.3, riska: 4.4 } },
    lastVisit: "2024-02-24",
    detailInformation: {
      mapLocationUrl: "https://maps.app.goo.gl/GHh5hLzBPwhNjomY8",
    },
    paymentMethods: ["cash"],
  },
  {
    title: "Soto Madura Cak Lemu",
    totalPrice: 66000,
    category: ["eatery"],
    menus: [
      { name: "Soto Campur + Nasi", price: 25000 },
      { name: "Soto 1/2 Daging + Nasi", price: 25000 },
      { name: "Kerupuk Udang", price: 2000 },
      { name: "Es Teh", price: 5000 },
    ],
    ourReview: { rating: { derby: 4.2, riska: 4.4 } },
    lastVisit: "2024-02-24",
    paymentMethods: ["cash", "qr"],
  },
  {
    title: "Kedai Jus Henny",
    totalPrice: 24000,
    category: ["beverage"],
    menus: [{ name: "Jus Alpukat", price: 12000 }],
    ourReview: { rating: { derby: 3, riska: 3.7 } },
    lastVisit: "2024-02-24",
    paymentMethods: ["cash", "qr"],
    detailInformation: {
      mapLocationUrl: "https://maps.app.goo.gl/KJWhk4Pn5axSec4o7",
    },
  },
  {
    title: "KooD - Kolaborasi Untuk Desa",
    totalPrice: 32000,
    category: ["vegan", "eatery"],
    menus: [
      { name: "Yamin", price: 16000 },
      { name: "Creamie Truffle", price: 16000 },
      { name: "Gelato Scoop", price: 15000 },
    ],
    ourReview: { rating: { derby: 4.5, riska: 0 } },
    lastVisit: "2024-02-25",
  },
  {
    title: "Nasi Goreng SULTAN",
    totalPrice: 60000,
    category: ["eatery"],
    menus: [
      { name: "Nasi Goreng Mawut", price: 25000 },
      {
        name: "Nasi Goreng Ayam",
        price: 25000,
        addons: [{ name: "Extra Telur", price: 8000 }],
      },
    ],
    ourReview: { rating: { derby: 4.1, riska: 4.4 } },
    lastVisit: "2024-02-25",
  },
  {
    title: "Djayantie",
    totalPrice: 78000,
    category: ["eatery"],
    menus: [
      { name: "Ayam Penyet", price: 20000 },
      { name: "Bebek Goreng", price: 30000 },
      { name: "Sate Kulit", price: 4000 },
      { name: "Nasi", price: 6000 },
      { name: "Es Teh", price: 6000 },
      { name: "Air Mineral", price: 6000 },
    ],
    ourReview: { rating: { derby: 3.6, riska: 0 } },
    lastVisit: "2024-02-25",
  },
  {
    title: "Pukisku",
    totalPrice: 21000,
    category: ["snack"],
    menus: [{ name: "Pukis Minini Aneka Rasa", price: 3500 }],
    ourReview: { rating: { derby: 3.6, riska: 0 } },
    lastVisit: "2024-02-25",
    detailInformation: {
      mapLocationUrl: "https://maps.app.goo.gl/BwFqrdN61SAXxDUR9",
    },
  },
  {
    title: "Sate Madura Denmadu",
    totalPrice: 36000,
    category: ["eatery"],
    menus: [
      { name: "Sate Ayam + Nasi", price: 15000 },
      { name: "Air Mineral", price: 5000 },
      { name: "Kerupuk", price: 1000 },
    ],
    ourReview: { rating: { derby: 2, riska: 3.8 } },
    lastVisit: "2024-02-27",
    detailInformation: {
      mapLocationUrl: "https://maps.app.goo.gl/yoJNMtKZXrrDRcHMA",
    },
    paymentMethods: ["cash"],
  },
  {
    title: "Es Kelapa Muda Murni",
    totalPrice: 10000,
    category: ["beverage"],
    menus: [
      { name: "Es Kelapa", price: 5000 },
      { name: "Kelapa Muda Utuh", price: 12000 },
    ],
    ourReview: { rating: { derby: 4.5, riska: 0 } },
    lastVisit: "2024-02-28",
    detailInformation: {
      mapLocationUrl: "https://maps.app.goo.gl/znCGUFtMV7e6PoRG8",
    },
    paymentMethods: ["cash", "qr"],
  },
  {
    title: "Chicken Crush",
    totalPrice: 69000,
    category: ["eatery"],
    menus: [
      { name: "Combo Duo Chicken Hot Korean BBQ", price: 31818 },
      { name: "Paket Nasi L Geprek", price: 17273 },
      { name: "Es Jeruk Nipis", price: 9091 },
    ],
    additionalCost: { ppn: "10%" },
    ourReview: { rating: { derby: 4.4, riska: 4.5 } },
    lastVisit: "2024-02-28",
  },
  {
    title: "Gosha",
    totalPrice: 50600,
    category: ["eatery", "bakery"],
    menus: [
      { name: "Grilled Chicken Salad", price: 44000 },
      { name: "Paket Nasi L Geprek", price: 17273 },
      { name: "Es Jeruk Nipis", price: 9091 },
    ],
    additionalCost: { ppn: "10%" },
    ourReview: { rating: { derby: 0, riska: 0 } },
    lastVisit: "2024-02-29",
  },
  {
    title: "Sate Madura Tukad Petanu",
    totalPrice: 15000,
    category: ["eatery"],
    menus: [{ name: "Nasi Sate Ayam", price: 15000 }],
    paymentMethods: ["cash"],
    ourReview: { rating: { derby: 4.6, riska: 0 } },
    lastVisit: "2024-02-29",
  },
  {
    title: "Bakmi Jawa Pa' Pom",
    totalPrice: 76000,
    category: ["eatery"],
    menus: [
      { name: "Bakmi Godog", price: 30000 },
      { name: "Bakmi Goreng", price: 30000 },
      { name: "Kerupuk Tenggiri", price: 6000 },
      { name: "Es Teh Manis", price: 6000 },
      { name: "Es Teh Tawar", price: 4000 },
    ],
    ourReview: { rating: { derby: 4.5, riska: 4 } },
    lastVisit: "2024-03-01",
  },
  {
    title: "Nasi Tempong Indra",
    totalPrice: 117000,
    category: ["eatery"],
    menus: [
      { name: "Nasi Tempong Bebek 1/4", price: 50000 },
      { name: "Nasi Tempong Ayam 1/4", price: 34000 },
      { name: "Kerupuk Tenggiri", price: 6000 },
      { name: "Es Teh Manis", price: 8000 },
      { name: "Es Teh Tawar", price: 6000 },
    ],
    additionalCost: { ppn: "10%", serviceCharge: "2%" },
    ourReview: { rating: { derby: 4, riska: 4.4 } },
    lastVisit: "2024-03-03",
  },
  {
    title: "Bebek dan Ayam Kampung Mas Budi",
    totalPrice: 103000,
    category: ["eatery"],
    menus: [
      { name: "Ayam Kampung Goreng", price: 39000 },
      { name: "Burung Malon Goreng", price: 33000 },
      { name: "Terong Goreng", price: 9000 },
      { name: "Nasi Putih", price: 6000 },
      { name: "Es Teh Manis", price: 6000 },
      { name: "Es Teh Tawar", price: 5000 },
    ],
    ourReview: { rating: { derby: 4.3, riska: 0 } },
    lastVisit: "2024-03-04",
  },
  {
    title: "Sate Sanera",
    totalPrice: 32000,
    category: ["eatery"],
    menus: [
      { name: "Sate Ayam", price: 12000 },
      { name: "Gulai Kambing", price: 12000 },
      { name: "Nasi Putih", price: 5000 },
      { name: "Lontong", price: 3000 },
    ],
    ourReview: { rating: { derby: 4.5, riska: 0 } },
    lastVisit: "2024-03-05",
  },
  {
    title: "Sushi Tei",
    totalPrice: 299000,
    category: ["eatery"],
    menus: [
      { name: "Ebi Ju", price: 62000 },
      { name: "Gekkikara Cheese Chicken Karaage", price: 54000 },
      { name: "Salmon Melted Cheese", price: 96000 },
      { name: "Inari Tuna Salad", price: 27000 },
      { name: "Cold Ocha", price: 7000 },
    ],
    additionalCost: { ppn: "10%", serviceCharge: "7.5%" },
    ourReview: { rating: { derby: 4.6, riska: 0 } },
    lastVisit: "2024-03-09",
  },
  {
    title: "Soto Ayam Ambengan",
    totalPrice: 36000,
    category: ["eatery"],
    menus: [{ name: "Soto Ayam Spesial", price: 18000 }],
    ourReview: { rating: { derby: 4.4, riska: 0 } },
    lastVisit: "2024-03-09",
  },
  {
    title: "Jus Masa Lalu",
    totalPrice: 30000,
    category: ["beverage"],
    menus: [
      { name: "Jus Alpukat Besar", price: 15000 },
      { name: "Jus Mangga Besar", price: 15000 },
      { name: "Jus Mix Berry Kecil", price: 25000 },
    ],
    ourReview: { rating: { derby: 4.6, riska: 0 } },
    lastVisit: "2024-07-14",
  },
  {
    title: "Tous Les Jours",
    totalPrice: 20000,
    category: ["bakery"],
    menus: [{ name: "Milk Coronet", price: 20000 }],
    ourReview: { rating: { derby: 4.8, riska: 0 } },
    lastVisit: "2024-03-09",
  },
  {
    title: "Iga Bakar Kang Asep",
    totalPrice: 114000,
    category: ["eatery"],
    menus: [
      { name: "Iga Bakar", price: 38000 },
      { name: "Iga Penyet", price: 38000 },
      { name: "Telur Kribo", price: 10000 },
      { name: "Tahu Goreng", price: 8000 },
      { name: "Nasi Putih", price: 5000 },
      { name: "Es Teh", price: 5000 },
    ],
    ourReview: { rating: { derby: 4, riska: 4.5 } },
    lastVisit: "2024-03-16",
  },
  {
    title: "Massimo",
    totalPrice: 40000,
    category: ["eatery", "parlor", "dessert"],
    menus: [{ name: "Sugar Cone", price: 20000 }],
    ourReview: { rating: { derby: 4.7, riska: 0 } },
    lastVisit: "2024-03-19",
  },
  {
    title: "Lalapan Cak No",
    totalPrice: 43000,
    category: ["eatery"],
    menus: [
      {
        name: "Nasi Ayam Goreng",
        price: 21000,
        addons: [{ name: "Ekstra Sambel", price: 1000 }],
      },
    ],
    ourReview: { rating: { derby: 3.9, riska: 0 } },
    lastVisit: "2024-04-22",
  },
  {
    title: "Nasi Goreng Juwara",
    totalPrice: 42000,
    category: ["eatery"],
    menus: [{ name: "Nasi Goreng Mawut", price: 21000 }],
    ourReview: { rating: { derby: 3.6, riska: 0 } },
    lastVisit: "2024-04-23",
  },
  {
    title: "Pahdi",
    totalPrice: 198000,
    category: ["cafe"],
    menus: [
      { name: "Jeruk Kintamani Coldbrew", price: 85000 },
      { name: "Cranberry Coldbrew", price: 85000 },
    ],
    ourReview: { rating: { derby: 4.5, riska: 0 } },
    additionalCost: { ppn: "10%", serviceCharge: "6%" },
    lastVisit: "2024-04-23",
  },
  {
    title: "Bebek Goreng Cak Iwan",
    totalPrice: 79000,
    category: ["eatery"],
    menus: [
      { name: "Nasi Bebek Kremes", price: 37000 },
      { name: "Nasi Ayam Kremes", price: 23000 },
      { name: "Tumis Kangkung", price: 9000 },
      { name: "Es Teh", price: 5000 },
      { name: "Air Mineral", price: 5000 },
    ],
    ourReview: { rating: { derby: 3.6, riska: 0 } },
    lastVisit: "2024-04-23",
  },
  {
    title: "Es Pisang Ijo Bang I",
    totalPrice: 30000,
    category: ["dessert"],
    menus: [{ name: "Es Pisang Ijo Special", price: 15000 }],
    ourReview: { rating: { derby: 4.2, riska: 0 } },
    lastVisit: "2024-03-23",
  },
  {
    title: "Sate Padang DaNeb",
    totalPrice: 50000,
    category: ["eatery"],
    menus: [{ name: "Sate Lidah 8 Tusuk", price: 25000 }],
    ourReview: { rating: { derby: 3.6, riska: 0 } },
    lastVisit: "2024-03-24",
    detailInformation: {
      mapLocationUrl: "https://maps.app.goo.gl/uLYC8XcSRYo6QgVi7    ",
    },
  },
  {
    title: "OHrisol",
    totalPrice: 42000,
    category: ["snack"],
    menus: [{ name: "Ragout Chicken (5pcs)", price: 39000 }],
    ourReview: { rating: { derby: 4.6, riska: 0 } },
    lastVisit: "2024-03-26",
  },
  {
    title: "Montato",
    totalPrice: 36000,
    category: ["snack"],
    menus: [
      {
        name: "Long Fries Cheese",
        price: 33000,
        addons: [{ name: "Cheese Sauce", price: 3000 }],
      },
    ],
    ourReview: { rating: { derby: 3.1, riska: 3.7 } },
    lastVisit: "2024-04-26",
  },
  {
    title: "Pasta on the Street",
    totalPrice: 118000,
    category: ["eatery"],
    menus: [
      { name: "Shrimp Rose Korean", price: 40000 },
      { name: "Grill Chicken Breast 150g Mushroom Sauce", price: 40000 },
      { name: "Chicken Tacos", price: 23000 },
      { name: "Es Teh", price: 6000 },
    ],
    detailInformation: {
      mapLocationUrl: "https://maps.app.goo.gl/LtkhqHJtE2by7EdS8",
    },
    additionalCost: { admin: "Rp3.000" },
    ourReview: { rating: { derby: 3.7, riska: 4.5 } },
    lastVisit: "2024-04-28",
  },
  {
    title: "Kopi Kenangan",
    totalPrice: 50000,
    category: ["beverage"],
    menus: [
      { name: "Blewah Tea with Blewah Jelly", price: 25000 },
      { name: "Raspberry Hibiscus", price: 25000 },
    ],
    ourReview: { rating: { derby: 3.7, riska: 0 } },
    lastVisit: "2024-04-27",
  },
  {
    title: "Bakso Solo Sanur",
    totalPrice: 30000,
    category: ["eatery"],
    menus: [
      { name: "Bakso Super", price: 13000 },
      { name: "Mie Ayam", price: 10000 },
      { name: "Es Teh", price: 4000 },
      { name: "Es Temulawak", price: 5000 },
    ],
    ourReview: { rating: { derby: 4.1, riska: 0 } },
    lastVisit: "2024-04-26",
  },
  {
    title: "Roti Kukus Srikaya BaliLa",
    totalPrice: 20000,
    category: ["bakery"],
    menus: [{ name: "Roti Kukus Isi", price: 4000 }],
    ourReview: { rating: { derby: 4.4, riska: 0 } },
    lastVisit: "2024-05-02",
  },
  {
    title: "Laoban Kopitiam",
    totalPrice: 112200,
    category: ["eatery"],
    menus: [
      { name: "Mie Laksa", price: 28000 },
      { name: "Chicken Mala", price: 30000 },
      { name: "Kaki Naga", price: 18000 },
      { name: "Teh Laoban Dingin", price: 12000 },
      { name: "Telur Kampung 1/2 Matang", price: 14000 },
    ],
    additionalCost: { ppn: "10%" },
    ourReview: { rating: { derby: 3.8, riska: 0 } },
    lastVisit: "2024-05-01",
  },
  {
    title: "Kintan Buffet",
    totalPrice: 767250,
    category: ["ayce"],
    menus: [{ name: "Special Yakiniku Adult", price: 329000 }],
    additionalCost: { serviceCharge: "6%", ppn: "10%" },
    ourReview: { rating: { derby: 4.2, riska: 0 } },
    lastVisit: "2024-05-04",
  },
  {
    title: "Hanamasa",
    totalPrice: 458700,
    category: ["ayce"],
    menus: [{ name: "Adult Package", price: 208500 }],
    additionalCost: { ppn: "10%" },
    ourReview: { rating: { derby: 4, riska: 0 } },
    lastVisit: "2022-04-19",
  },
  {
    title: "Kaneshiro",
    totalPrice: 239800,
    category: ["ayce"],
    menus: [{ name: "Adult Package", price: 109800 }],
    additionalCost: { ppn: "10%" },
    ourReview: { rating: { derby: 3.9, riska: 0 } },
    lastVisit: "2023-02-09",
  },
  {
    title: "Soto Ayam Surabaya",
    totalPrice: 239800,
    category: ["eatery"],
    menus: [
      { name: "Soto Jeroan", price: 20000 },
      { name: "Soto Kulit", price: 20000 },
    ],
    ourReview: { rating: { derby: 3.7, riska: 0 } },
    lastVisit: "2023-07-15",
  },
  {
    title: "Ronde Barong",
    totalPrice: 31000,
    category: ["beverage", "snack"],
    menus: [
      { name: "Ronde Jadoel", price: 13000 },
      { name: "Ronde Matcha (Cold)", price: 18000 },
    ],
    ourReview: { rating: { derby: 3.7, riska: 0 } },
    lastVisit: "2024-05-05",
    detailInformation: {
      mapLocationUrl: "https://maps.app.goo.gl/cVyGHwTT67A1F8a46",
    },
  },
  {
    title: "Lalapan Penyetan 18",
    totalPrice: 44000,
    category: ["eatery"],
    menus: [
      { name: "Ayam Negri", price: 15000 },
      { name: "Lele", price: 10000 },
      { name: "Nasi Putih", price: 5000 },
      { name: "Sambal Bawang", price: 3000 },
      { name: "Es Teh Tawar", price: 2000 },
    ],
    ourReview: { rating: { derby: 3.7, riska: 0 } },
    lastVisit: "2024-05-07",
    detailInformation: {
      mapLocationUrl: "https://maps.app.goo.gl/Aj5g3deWnpFaFjsN7",
    },
  },
  {
    title: "Michilo Ramen",
    totalPrice: 64000,
    category: ["eatery"],
    menus: [
      {
        name: "Ramen Katsu",
        price: 25000,
        addons: [
          { name: "Kuah Creamy", price: 0 },
          { name: "Level 3", price: 0 },
        ],
      },
      {
        name: "Ramen Dumpling Ayam",
        price: 23000,
        addons: [
          { name: "Kuah Tamago Special", price: 0 },
          { name: "Level 4", price: 0 },
        ],
      },
      { name: "Chicken Kaarage", price: 10000 },
      { name: "Es Teh Tawar", price: 3000 },
    ],
    ourReview: { rating: { derby: 4.2, riska: 0 } },
    lastVisit: "2024-05-09",
    detailInformation: {
      mapLocationUrl: "https://maps.app.goo.gl/z55Vzq2b24pHm9Sj6",
    },
  },
  {
    title: "Warung Klepom Gianyar 1980",
    totalPrice: 48000,
    category: ["snack"],
    menus: [
      { name: "Klepon Mix Isi 33", price: 30000 },
      { name: "Loloh Cemcem", price: 8000 },
      { name: "Cendol Jelly", price: 10000 },
    ],
    ourReview: { rating: { derby: 4.5, riska: 4 } },
    lastVisit: "2024-05-09",
    detailInformation: {
      mapLocationUrl: "https://maps.app.goo.gl/2qjmjkf9WaHmL9E4A",
    },
  },
  {
    title: "Martabak Pecenongan 78",
    totalPrice: 55000,
    category: ["snack"],
    menus: [{ name: "Martabak Spesial Daging Sapi 2 Telor", price: 55000 }],
    ourReview: { rating: { derby: 4.5, riska: 0 } },
    lastVisit: "2024-05-09",
  },
  {
    title: "Fruit in Bottle",
    totalPrice: 39000,
    category: ["beverage"],
    menus: [
      { name: "Pokcoy Pineapple 350ml", price: 19000 },
      { name: "Soursop 600ml", price: 20000 },
    ],
    ourReview: { rating: { derby: 4.3, riska: 0 } },
    lastVisit: "2024-05-09",
  },
  {
    title: "Hakomamie",
    totalPrice: 44000,
    category: ["eatery"],
    menus: [
      { name: "Mie Jebew Level 4", price: 15000 },
      { name: "Mie Yamin Manis", price: 13000 },
      { name: "Pangsit Goreng", price: 4000 },
      { name: "Es Teh", price: 4000 },
    ],
    ourReview: { rating: { derby: 4.1, riska: 3.7 } },
    lastVisit: "2024-05-11",
    paymentMethods: ["cash", "qr"],
  },
  {
    title: "Lalapan Apek Dewe",
    totalPrice: 68000,
    category: ["eatery"],
    menus: [
      { name: "Nasi Uduk", price: 8000 },
      { name: "Lele", price: 10000 },
      { name: "Telur", price: 10000 },
      { name: "Ampela", price: 5000 },
      { name: "Kulit", price: 5000 },
      { name: "Ceker", price: 2000 },
      { name: "Terong", price: 5000 },
      { name: "Tahu", price: 2000 },
      { name: "Tempe", price: 3000 },
      { name: "Es Teh", price: 5000 },
    ],
    ourReview: { rating: { derby: 4, riska: 4.5 } },
    lastVisit: "2024-05-11",
    paymentMethods: ["cash", "qr"],
  },
  {
    title: "Imadji",
    totalPrice: 47000,
    category: ["cafe"],
    menus: [
      { name: "Imagine", price: 21000 },
      { name: "Falling in Love", price: 22000 },
    ],
    ourReview: { rating: { derby: 4, riska: 3.8 } },
    lastVisit: "2024-05-12",
    additionalCost: { ppn: "10%" },
  },
  {
    title: "Xiyue",
    totalPrice: 20000,
    category: ["parlor", "beverage"],
    menus: [
      { name: "Signature Thai Tea Ice Cream Cone", price: 8000 },
      { name: "Xi Yue Signature Choco Ice Cream Cone", price: 12000 },
    ],
    ourReview: { rating: { derby: 4.1, riska: 0 } },
    lastVisit: "2024-05-12",
  },
  {
    title: "Pepper Lunch",
    totalPrice: 192600,
    category: ["eatery"],
    menus: [
      { name: "Pepper Steak", price: 146363 },
      { name: "Happy Beef Pepper Rice", price: 0 },
      { name: "Iced Green Tea", price: 11818 },
    ],
    ourReview: { rating: { derby: 3, riska: 4.5 } },
    lastVisit: "2024-05-14",
  },
  {
    title: "Gamchi",
    totalPrice: 44000,
    category: ["snack"],
    menus: [{ name: "Gamchi Sweet Almond", price: 40000 }],
    ourReview: { rating: { derby: 4, riska: 0 } },
    lastVisit: "2024-05-18",
  },
  {
    title: "Nyarik Kopi",
    totalPrice: 15000,
    category: ["coffee"],
    menus: [
      { name: "Kopi Susu", price: 10000 },
      { name: "Kudapan 3pcs (jagung/ubi/pisang)", price: 5000 },
    ],
    ourReview: { rating: { derby: 4.2, riska: 3.8 } },
    lastVisit: "2024-05-18",
    detailInformation: {
      mapLocationUrl: "https://maps.app.goo.gl/YRWZGmfwb4b5cJio8",
    },
  },
  {
    title: "Pink Tempong",
    totalPrice: 106000,
    category: ["eatery"],
    menus: [
      { name: "Nasi Tempong Cumi Hitam", price: 38000 },
      { name: "Nasi Tempong Babat", price: 29000 },
      { name: "Kulit Ayam", price: 24000 },
      { name: "Tahu Petis Unyil", price: 15000 },
    ],
    ourReview: { rating: { derby: 4.2, riska: 4.6 } },
    lastVisit: "2024-05-18",
  },
  {
    title: "Mega Kitchen",
    totalPrice: 23000,
    category: ["snack"],
    menus: [
      { name: "Paket Risol Mix (4pcs)", price: 18000 },
      { name: "Risol Ayam Mayo", price: 5000 },
      { name: "Risol Ayam Mozzarella", price: 5000 },
      { name: "Risol Sosis Telur Mayo", price: 5000 },
      { name: "Risol Rogut", price: 5000 },
    ],
    ourReview: { rating: { derby: 4.4, riska: 0 } },
    lastVisit: "2024-05-19",
  },
  {
    title: "Momoyo",
    totalPrice: 26000,
    category: ["beverage"],
    menus: [{ name: "Jumbo fruit Tea 1L", price: 26000 }],
    ourReview: { rating: { derby: 4.4, riska: 4.8 } },
    lastVisit: "2024-05-19",
  },
  {
    title: "Geprek 19",
    totalPrice: 20000,
    category: ["eatery"],
    menus: [
      { name: "Nasi Ayam Geprek", price: 10000 },
      { name: "Ayam Geprek", price: 9000 },
    ],
    ourReview: { rating: { derby: 3.3, riska: 0 } },
    lastVisit: "2024-05-20",
  },
  {
    title: "Cireng Rujak Bali",
    totalPrice: 18000,
    category: ["snack"],
    menus: [
      { name: "Cireng Rujak", price: 9000 },
      { name: "Cimol", price: 9000 },
    ],
    ourReview: { rating: { derby: 4.4, riska: 3.5 } },
    lastVisit: "2024-05-25",
  },
  {
    title: "Babycumi Omahdee",
    totalPrice: 74000,
    category: ["eatery"],
    menus: [
      { name: "Paket Cumi Hitam", price: 26000 },
      {
        name: "Paket Babycumi Jumbo",
        price: 34000,
        addons: [{ name: "Nasi Uleg Sambal Ijo", price: 2000 }],
      },
      { name: "Mendoan", price: 6000 },
      { name: "Es Teh Tawar", price: 6000 },
    ],
    ourReview: { rating: { derby: 4, riska: 3.8 } },
    lastVisit: "2024-05-25",
  },
  {
    title: "Powder Room",
    totalPrice: 104000,
    category: ["cafe"],
    menus: [
      { name: "Botanical Bomboloni Vanilla Brulee", price: 45000 },
      { name: "Botanical Bomboloni Ispahan", price: 45000 },
    ],
    additionalCost: { serviceCharge: "5%", ppn: "10%" },
    ourReview: { rating: { derby: 4.5, riska: 4.6 } },
    lastVisit: "2024-05-26",
  },
  {
    title: "Daily Fat Burger",
    totalPrice: 123000,
    category: ["eatery"],
    menus: [
      {
        name: "Beef Date Package",
        price: 115000,
        addons: [
          { name: "Two Single Beef Burger", price: 0 },
          { name: "Onion Ring", price: 0 },
          { name: "Fire Wings", price: 0 },
          { name: "Fries", price: 0 },
        ],
      },
      { name: "Ice Tea", price: 8000 },
    ],
    ourReview: { rating: { derby: 4.5, riska: 4.5 } },
    lastVisit: "2024-05-26",
  },
  {
    title: "Sate Madura Hayam Wuruk",
    totalPrice: 46000,
    category: ["eatery"],
    menus: [
      { name: "Sate Ayam", price: 12000 },
      { name: "Gule Kambing", price: 12000 },
      { name: "Nasi Putih", price: 5000 },
    ],
    ourReview: { rating: { derby: 3.6, riska: 3.8 } },
    lastVisit: "2024-05-27",
  },
  {
    title: "Mie Kober",
    totalPrice: 52000,
    category: ["eatery"],
    menus: [
      { name: "Chicken Cheese Roll", price: 10909 },
      { name: "Udang Rambutan", price: 11818 },
      { name: "Udang Keju", price: 13636 },
      { name: "Siomay Pedas", price: 10909 },
    ],
    ourReview: { rating: { derby: 4, riska: 0 } },
    lastVisit: "2024-05-28",
  },
  {
    title: "Spesial Soto Boyolali",
    totalPrice: 40500,
    category: ["eatery"],
    menus: [
      { name: "Soto Bungkus Sapi", price: 15000 },
      { name: "Soto Bungkus Ayam", price: 15000 },
      { name: "Nasi Putih", price: 5000 },
      { name: "Tempe Mendoan", price: 2000 },
    ],
    additionalCost: { ppn: "10%" },
    ourReview: { rating: { derby: 3.5, riska: 0 } },
    lastVisit: "2024-05-29",
  },
  {
    title: "Resto Apung",
    totalPrice: 145000,
    category: ["eatery"],
    menus: [
      { name: "Mujair Nyat Nyat 1/2kg", price: 49000 },
      { name: "Plecing", price: 17500 },
      { name: "Fuyung Hai", price: 20000 },
      { name: "Es Jeruk", price: 15000 },
      { name: "Es Teh Tawar", price: 10000 },
    ],
    additionalCost: { ppn: "15%" },
    ourReview: { rating: { derby: 4.1, riska: 4.2 } },
    lastVisit: "2024-06-01",
  },
  {
    title: "KOI The",
    totalPrice: 28000,
    category: ["beverage"],
    menus: [{ name: "Assam Black Tea", price: 28000 }],
    ourReview: { rating: { derby: 4, riska: 0 } },
    lastVisit: "2024-06-02",
  },
  {
    title: "The Bakso 2020",
    totalPrice: 96000,
    category: ["eatery", "vegan"],
    menus: [
      { name: "Bakso Sapi Lengkap", price: 38000 },
      { name: "Mie Ayam Bakso Daging", price: 29000 },
      { name: "Es Teh", price: 8000 },
    ],
    additionalCost: { serviceCharge: "5%", ppn: "10%" },
    ourReview: { rating: { derby: 4, riska: 0 } },
    lastVisit: "2024-06-02",
  },
  {
    title: "Gauk Dimsum",
    totalPrice: 49500,
    category: ["eatery"],
    menus: [
      { name: "Scallop", price: 1000 },
      { name: "Chikuwa", price: 1000 },
      { name: "Flower Twister", price: 1000 },
      { name: "Bola Lobster", price: 2000 },
      { name: "Bola Salmon", price: 2000 },
      { name: "Cheese Dumpling", price: 2000 },
      { name: "Chicken Dumpling", price: 2000 },
      { name: "Siomay Ranjau", price: 3000 },
      { name: "Siomay Smoked Beef", price: 3000 },
      { name: "Siomay Jamur", price: 3000 },
      { name: "Ekado", price: 3000 },
    ],
    additionalCost: { ppn: "10%" },
    ourReview: { rating: { derby: 4.1, riska: 0 } },
    lastVisit: "2024-06-30",
  },
  {
    title: "Sate Padang Maimbau",
    totalPrice: 48000,
    category: ["eatery"],
    menus: [{ name: "Sate Padang Lontong", price: 24000 }],
    ourReview: { rating: { derby: 4.2, riska: 4.5 } },
    lastVisit: "2024-06-07",
    detailInformation: {
      mapLocationUrl: "https://maps.app.goo.gl/v1Aiqwn6UAFtjjqKA",
    },
  },
  {
    title: "Bakso Harapan Baru",
    totalPrice: 65000,
    category: ["ayce"],
    menus: [
      { name: "Bakso Makan di Tempat", price: 25000 },
      { name: "Es Teh Tawar", price: 4000 },
      { name: "Es Teh Manis", price: 5000 },
    ],
    ourReview: { rating: { derby: 2.6, riska: 3 } },
    lastVisit: "2024-06-08",
  },
  {
    title: "Sate Padang Mak Itam",
    totalPrice: 78000,
    category: ["eatery"],
    paymentMethods: ["cash"],
    menus: [
      { name: "Sate Padang 1 Tusuk", price: 2500 },
      { name: "Lontong", price: 3000 },
    ],
    ourReview: { rating: { derby: 4.3, riska: 4.6 } },
    lastVisit: "2024-06-09",
  },
  {
    title: "Tahu Tek Surabaya",
    totalPrice: 20000,
    category: ["eatery"],
    menus: [{ name: "Tahu Tek", price: 10000 }],
    ourReview: { rating: { derby: 4.5, riska: 4.6 } },
    lastVisit: "2024-06-11",
    detailInformation: {
      mapLocationUrl: "https://maps.app.goo.gl/9M4Ft7haPPk28EMQ9",
    },
  },
  {
    title: "Kirana Risoles",
    totalPrice: 11000,
    category: ["snack"],
    paymentMethods: ["cash", "qr"],
    menus: [
      { name: "Risoles Ori (rogut)", price: 3000 },
      { name: "Risoles Mayo", price: 4000 },
      { name: "Risoles Bolognaise", price: 4000 },
    ],
    ourReview: { rating: { derby: 4.2, riska: 0 } },
    lastVisit: "2024-06-12",
    detailInformation: {
      mapLocationUrl: "https://maps.app.goo.gl/odbVXYGeDE9AsRPu6",
    },
  },
  {
    title: "Mie Ayam 38 Jakarta",
    totalPrice: 56000,
    category: ["eatery"],
    paymentMethods: ["cash", "qr"],
    menus: [
      { name: "Mie Ayam Komplit", price: 25000 },
      { name: "Es Teh Tawar", price: 3000 },
    ],
    ourReview: { rating: { derby: 3.9, riska: 4.6 } },
    lastVisit: "2024-06-13",
  },
  {
    title: "Tahu Tek Cak Poer",
    totalPrice: 37000,
    category: ["eatery"],
    paymentMethods: ["cash", "qr"],
    menus: [
      { name: "Tahu Tek Telor", price: 16000 },
      { name: "Air Mineral", price: 5000 },
    ],
    ourReview: { rating: { derby: 4.4, riska: 4.6 } },
    lastVisit: "2024-06-14",
  },
  {
    title: "Mie Bakso Lidi",
    totalPrice: 70000,
    category: ["eatery"],
    menus: [
      {
        name: "Yamien Ayam Jamur",
        price: 19000,
        addons: [{ name: "Kuah Komplit", price: 11000 }],
      },
      {
        name: "Bakmie Ayam Jamur",
        price: 19000,
        addons: [{ name: "Kuah Komplit", price: 11000 }],
      },
      { name: "Es Teh Tawar", price: 3000 },
      { name: "Es Temulawak", price: 7000 },
    ],
    ourReview: { rating: { derby: 4, riska: 4.6 } },
    lastVisit: "2024-06-15",
  },
  {
    title: "Pukis Kota Bangka",
    totalPrice: 30000,
    category: ["snack"],
    menus: [{ name: "Pukis 5 pcs Keju", price: 30000 }],
    ourReview: { rating: { derby: 4, riska: 4.4 } },
    lastVisit: "2024-06-15",
  },
  {
    title: "Geprek Sultan",
    totalPrice: 16000,
    category: ["eatery"],
    paymentMethods: ["cash", "qr"],
    menus: [{ name: "Ayam Geprek", price: 8000 }],
    ourReview: { rating: { derby: 4.3, riska: 4.8 } },
    lastVisit: "2024-06-15",
  },
  {
    title: "Teazzi",
    totalPrice: 55000,
    category: ["beverage"],
    menus: [
      { name: "Four Seasons Oolong", price: 30000 },
      { name: "Deep Roast Oolong", price: 25000 },
    ],
    ourReview: { rating: { derby: 4, riska: 0 } },
    lastVisit: "2024-06-16",
  },
  {
    title: "Citraloka Iga Bakar & Kare Pedas",
    totalPrice: 93000,
    category: ["eatery"],
    menus: [
      { name: "Kari Koyor + Lontong", price: 30000 },
      { name: "Iga Bakar", price: 40000 },
      { name: "Nasi Putih", price: 7000 },
      { name: "Es Teh Jumbo", price: 8000 },
    ],
    ourReview: { rating: { derby: 4.1, riska: 4 } },
    lastVisit: "2024-06-17",
  },
  {
    title: "Kunudhhani",
    totalPrice: 118800,
    category: ["cafe"],
    menus: [
      { name: "Iced Matcha Latte", price: 25000 },
      { name: "Chocolate Frappe", price: 30000 },
      { name: "Almond Croissant", price: 28000 },
      { name: "French Fries", price: 25000 },
    ],
    additionalCost: { ppn: "10%" },
    ourReview: { rating: { derby: 4.1, riska: 0 } },
    lastVisit: "2024-06-18",
  },
  {
    title: "Cood Coconut",
    totalPrice: 35000,
    category: ["beverage"],
    menus: [{ name: "Smoothies Cood Original", price: 35000 }],
    ourReview: { rating: { derby: 4.1, riska: 4 } },
    lastVisit: "2024-06-18",
  },
  {
    title: "Kebab Turki Baba Rafi",
    totalPrice: 42000,
    category: ["snack"],
    paymentMethods: ["cash"],
    menus: [
      { name: "Kebab Black", price: 21000 },
      { name: "Kebab Sapi", price: 21000 },
    ],
    ourReview: { rating: { derby: 3.8, riska: 0 } },
    lastVisit: "2024-06-18",
  },
  {
    title: "Wizzmie",
    totalPrice: 80000,
    category: ["eatery"],
    menus: [
      { name: "Mie Goyang Lv 1", price: 12000 },
      { name: "Mie Goyang Lv 3", price: 12000 },
      { name: "Udang Keju", price: 11500 },
      { name: "Spicy Chicken Wing", price: 14000 },
      { name: "Green Tea Frappe", price: 13000 },
      { name: "Ice Tea Jumbo", price: 10000 },
    ],
    ourReview: { rating: { derby: 4, riska: 4 } },
    lastVisit: "2024-06-19",
  },
  {
    title: "Chuck Wie",
    totalPrice: 44000,
    category: ["eatery"],
    paymentMethods: ["cash", "qr"],
    menus: [
      { name: "Chuck Mie Chili Oil Basic", price: 16000 },
      { name: "Chuck Mie Asin Basic", price: 16000 },
      { name: "Udang Keju", price: 11500 },
      { name: "Es Teh Tawar", price: 5000 },
      { name: "Teh Vanilla", price: 7000 },
    ],
    ourReview: { rating: { derby: 3.9, riska: 4.6 } },
    lastVisit: "2024-06-21",
  },
  {
    title: "Kemah",
    totalPrice: 165000,
    category: ["cafe"],
    menus: [
      { name: "Tom Yam", price: 36000 },
      { name: "Chicken Nanban", price: 42000 },
      { name: "Mango Bingsu", price: 36000 },
    ],
    additionalCost: { ppn: "10%" },
    ourReview: { rating: { derby: 4.2, riska: 0 } },
    lastVisit: "2024-06-22",
  },
  {
    title: "Sate Padang Jaya",
    totalPrice: 74000,
    category: ["eatery"],
    paymentMethods: ["cash"],
    menus: [
      { name: "Sate Padang 1 Tusuk", price: 25000 },
      { name: "Ketupat", price: 6000 },
      { name: "Keripik Singkong", price: 2000 },
    ],
    ourReview: { rating: { derby: 4, riska: 4.8 } },
    lastVisit: "2024-06-23",
  },
  {
    title: "Disaosin",
    totalPrice: 44000,
    category: ["eatery"],
    paymentMethods: ["cash", "qr"],
    menus: [
      { name: "Paket Nasi Ayam Kriuk Saos Texas", price: 22000 },
      { name: "Paket Nasi Ayam Kriuk Sambel Bawang", price: 22000 },
    ],
    ourReview: { rating: { derby: 0, riska: 0 } },
    lastVisit: "2024-06-26",
  },
  {
    title: "Lalapan Surabaya Cak Tiar",
    totalPrice: 43000,
    category: ["eatery"],
    paymentMethods: ["cash"],
    menus: [
      { name: "Nasi Ayam Goreng", price: 21000 },
      { name: "Nasi Lele Goreng", price: 15000 },
      { name: "Es Teh", price: 3000 },
      { name: "Kerupuk", price: 1000 },
    ],
    ourReview: { rating: { derby: 4.2, riska: 0 } },
    detailInformation: {
      mapLocationUrl: "https://maps.app.goo.gl/Mfysd4NeUiE8aB3WA",
    },
    lastVisit: "2024-06-28",
  },
  {
    title: "Rumah Makan Balenong",
    totalPrice: 72000,
    category: ["eatery"],
    menus: [
      { name: "Nasi Cincang Telor Dadar", price: 30000 },
      { name: "Nasi Terong Ikan Telor Dadar", price: 30000 },
      { name: "Es Teh", price: 5000 },
      { name: "Air Mineral", price: 5000 },
      { name: "Kerupuk", price: 2000 },
    ],
    ourReview: { rating: { derby: 4.5, riska: 4.8 } },
    detailInformation: {
      mapLocationUrl: "https://maps.app.goo.gl/YjKAZJLxcuQGSL2x9",
    },
    lastVisit: "2024-09-29",
  },
  {
    title: "Kebuli Jordan",
    totalPrice: 99000,
    category: ["eatery"],
    menus: [
      { name: "Nasi Kebuli Ayam Dada", price: 40000 },
      { name: "Nasi Kebuli Ayam Paha", price: 40000 },
      { name: "Es Teh Bos", price: 7000 },
      { name: "Teh Arab Maklum", price: 12000 },
    ],
    ourReview: { rating: { derby: 4.5, riska: 0 } },

    lastVisit: "2024-06-30",
  },
  {
    title: "Mie Ayam Bakso Pawon Wong Solo",
    totalPrice: 68000,
    category: ["eatery"],
    menus: [{ name: "Bakso Komplit", price: 34000 }],
    ourReview: { rating: { derby: 4, riska: 0 } },
    detailInformation: {
      mapLocationUrl: "https://maps.app.goo.gl/nMUW3paahxfgT2Vv5",
    },
    lastVisit: "2024-07-03",
  },
  {
    title: "Dum Dum",
    totalPrice: 53000,
    category: ["beverage"],
    menus: [
      { name: "Dumilo Dinosaur", price: 26000 },
      { name: "Passion Fruit Green Tea with Konjac Balls", price: 27000 },
    ],
    ourReview: { rating: { derby: 3.9, riska: 0 } },
    lastVisit: "2024-07-05",
  },
  {
    title: "Bintang Lima Bakmi & Bubur Ayam",
    totalPrice: 116000,
    category: ["eatery"],
    menus: [
      { name: "Bakmi Ayam Kampung", price: 32000 },
      { name: "Ayam Panggang 1/4", price: 28000 },
      { name: "Bakso Goreng", price: 8000 },
      { name: "Bubur Ikan", price: 28000 },
      { name: "Es Teh Tawar", price: 8000 },
      { name: "Es Liang Teh", price: 12000 },
    ],
    ourReview: { rating: { derby: 4.4, riska: 0 } },
    lastVisit: "2024-07-06",
  },
  {
    title: "Soto & Rawon Cak So",
    totalPrice: 58000,
    category: ["eatery"],
    menus: [
      { name: "Nasi Rawon", price: 25000 },
      { name: "Soto Ayam", price: 20000 },
      { name: "Es Teh", price: 5000 },
      { name: "Kerupuk Udang", price: 3000 },
    ],
    ourReview: { rating: { derby: 4.4, riska: 0 } },
    lastVisit: "2024-07-13",
  },
  {
    title: "Mako",
    totalPrice: 27500,
    category: ["bakery"],
    menus: [
      { name: "Croissant", price: 12500 },
      { name: "Croissant Coklat", price: 15000 },
    ],
    ourReview: { rating: { derby: 4.2, riska: 0 } },
    lastVisit: "2024-07-11",
  },
  {
    title: "Seiya Ramen",
    totalPrice: 236000,
    category: ["eatery"],
    menus: [
      { name: "Spicy Gyu-Jan Ramen", price: 73000 },
      { name: "Gyu-Jan Ramen", price: 71000 },
      { name: "Special Tori-Jan Ramen", price: 71000 },
      { name: "Ocha", price: 15000 },
      { name: "Chicken Gyoza", price: 30000 },
      { name: "Kaarage Set", price: 18000 },
    ],
    additionalCost: { ppn: "10%", serviceCharge: "5%" },
    ourReview: { rating: { derby: 4.2, riska: 0 } },
    lastVisit: "2024-09-15",
  },
  {
    title: "Mendoan PlatR",
    totalPrice: 20000,
    category: ["snack"],
    paymentMethods: ["cash", "qr"],
    menus: [{ name: "Mendoan Wareg Dewe Isi 5", price: 20000 }],
    ourReview: { rating: { derby: 4, riska: 0 } },
    lastVisit: "2024-07-16",
  },
  {
    title: "Purnama Bakso Blitar",
    totalPrice: 68000,
    category: ["eatery"],
    paymentMethods: ["cash", "qr"],
    menus: [
      { name: "Bakso Beranak Sapi", price: 29000 },
      { name: "Kerupuk Pangsit", price: 1000 },
      { name: "Air Mineral", price: 5000 },
    ],
    ourReview: { rating: { derby: 3.8, riska: 4 } },
    lastVisit: "2024-07-20",
  },
  {
    title: "Pecel Madiun Renon",
    totalPrice: 46000,
    category: ["eatery"],
    paymentMethods: ["cash", "qr"],
    menus: [
      { name: "Nasi Pecel", price: 12000 },
      { name: "Pecel", price: 10000 },
      { name: "Telur Ceplok", price: 5000 },
      { name: "Telur Dadar", price: 7000 },
      { name: "Dadar Jagung", price: 2000 },
      { name: "Perkedel", price: 3000 },
      { name: "Air Mineral", price: 5000 },
    ],
    ourReview: { rating: { derby: 3.8, riska: 4 } },
    lastVisit: "2024-07-21",
  },
  {
    title: "Mie Aceh Bang Zi",
    totalPrice: 67000,
    category: ["eatery"],
    paymentMethods: ["cash", "qr"],
    menus: [
      { name: "Nasi Goreng Komplit", price: 32000 },
      { name: "Nasi Goreng Ayam", price: 18000 },
      { name: "Es Teh", price: 5000 },
      { name: "Kerupuk", price: 1000 },
    ],
    ourReview: { rating: { derby: 3.8, riska: 4.7 } },
    lastVisit: "2024-07-21",
  },
  {
    title: "Tomoro Coffee",
    totalPrice: 55000,
    category: ["coffee", "beverage"],
    menus: [
      { name: "Coconut Choco Iced Large", price: 25000 },
      { name: "Matcha Latte Iced Large", price: 30000 },
    ],
    ourReview: { rating: { derby: 4, riska: 4.7 } },
    lastVisit: "2024-07-13",
  },
  {
    title: "Bakso Solo Super Mas Supar",
    totalPrice: 41000,
    category: ["eatery"],
    paymentMethods: ["cash"],
    menus: [
      { name: "Bakso Urat", price: 15000 },
      { name: "Bakso Campur", price: 15000 },
      { name: "Lontong", price: 5000 },
      { name: "Air Mineral", price: 5000 },
      { name: "Kerupuk", price: 1000 },
    ],
    ourReview: { rating: { derby: 3.2, riska: 4 } },
    lastVisit: "2024-07-27",
  },
  {
    title: "Es Teler 33",
    totalPrice: 31000,
    category: ["dessert"],
    menus: [
      { name: "Es Teler Durian", price: 18000 },
      { name: "Es Teler Chokeju", price: 13000 },
    ],
    ourReview: { rating: { derby: 3.7, riska: 0 } },
    lastVisit: "2024-07-27",
  },
  {
    title: "Sushitou",
    totalPrice: 246000,
    category: ["eatery"],
    menus: [
      { name: "Nigiri Set", price: 78000 },
      { name: "Salmon Double Set", price: 50000 },
      { name: "Salmon Aburi Roll", price: 20000 },
      { name: "Salmon Skin", price: 35000 },
      { name: "Ocha", price: 15000 },
    ],
    additionalCost: { ppn: "10%", serviceCharge: "5%" },
    ourReview: { rating: { derby: 3.4, riska: 4 } },
    lastVisit: "2024-07-28",
  },
  {
    title: "Rujak Cingur Cobek Gede",
    totalPrice: 42000,
    category: ["eatery"],
    menus: [
      { name: "Tahu Tek Telur", price: 15000 },
      { name: "Tahu Campur", price: 20000 },
      { name: "Air Mineral", price: 4000 },
      { name: "Kerupuk Rambak", price: 3000 },
    ],
    additionalCost: { ppn: "10%", serviceCharge: "5%" },
    ourReview: { rating: { derby: 4.4, riska: 4.5 } },
    lastVisit: "2024-07-28",
  },
  {
    title: "Ramen YA!",
    totalPrice: 117800,
    category: ["eatery"],
    menus: [
      { name: "King Spicy Legendary Chicken", price: 54000 },
      { name: "King Chicken Hot", price: 60500 },
      { name: "Grilled Chicken Gyoza", price: 4000 },
      { name: "Sweet Ocha", price: 15000 },
      { name: "Ocha", price: 11800 },
    ],
    additionalCost: { ppn: "10%", serviceCharge: "5%" },
    ourReview: { rating: { derby: 4.3, riska: 4.5 } },
    lastVisit: "2024-08-03",
  },
  {
    title: "Rumah Makan Sari Bundo Jaya",
    totalPrice: 117800,
    category: ["eatery"],
    menus: [
      { name: "Nasi Padang Rendang + Teri Balado", price: 27000 },
      { name: "Nasi Padang Cincang + Telur Dadar", price: 33000 },
      { name: "Es Teh", price: 5000 },
    ],
    ourReview: { rating: { derby: 3.8, riska: 0 } },
    lastVisit: "2024-08-10",
  },
  {
    title: "Soto Ayam Cak Man",
    totalPrice: 80000,
    category: ["eatery"],
    menus: [
      { name: "Soto Ayam Istimewa", price: 30000 },
      { name: "Nasi Putih", price: 7000 },
      { name: "Es Teh Manis", price: 7000 },
      { name: "Es Teh Tawar", price: 6000 },
    ],
    ourReview: { rating: { derby: 3.7, riska: 3.8 } },
    lastVisit: "2024-08-25",
  },
  {
    title: "Zenbu",
    totalPrice: 249000,
    category: ["eatery"],
    menus: [
      {
        name: "Chicken Omurice",
        price: 75000,
        addons: [{ name: "Cheese Omurice", price: 15000 }],
      },
      { name: "Chicken Ramen", price: 79000 },
      { name: "Ocha Ice", price: 18000 },
      { name: "Mozaru Birthday", price: 0 },
    ],
    ourReview: { rating: { derby: 4.1, riska: 4 } },
    lastVisit: "2024-09-01",
  },
  {
    title: "Boost Juice",
    totalPrice: 55000,
    category: ["beverage"],
    menus: [{ name: "Berry Crush Original", price: 55000 }],
    ourReview: { rating: { derby: 4.2, riska: 0 } },
    lastVisit: "2024-09-24",
  },
  {
    title: "Chikuro",
    totalPrice: 98000,
    category: ["snack"],
    menus: [
      {
        name: "Chiro Large",
        price: 36000,
        addons: [
          { name: "Original", price: 0 },
          { name: "Japanese Nori", price: 0 },
          { name: "Mentai", price: 0 },
        ],
      },
      {
        name: "Chiro Large",
        price: 36000,
        addons: [
          { name: "Spicy", price: 0 },
          { name: "Japanese Truffle", price: 0 },
          { name: "Barbeque", price: 0 },
        ],
      },
    ],
    ourReview: { rating: { derby: 4.1, riska: 0 } },
    lastVisit: "2024-09-16",
  },
  {
    title: "XXI Cafe",
    totalPrice: 85000,
    category: ["snack"],
    menus: [{ name: "Snack Combo 1", price: 85000 }],
    ourReview: { rating: { derby: 4.2, riska: 0 } },
    lastVisit: "2024-09-28",
  },
  {
    title: "Sajiwa",
    totalPrice: 79000,
    category: ["eatery"],
    menus: [
      { name: "Mie Yamien Asin Baso", price: 32000 },
      { name: "Mie Ayam Jamur Baso", price: 35000 },
      { name: "Air Mineral (Free Refill)", price: 6000 },
      { name: "Es Teh Tawar (Free Refill)", price: 6000 },
    ],
    ourReview: { rating: { derby: 3.8, riska: 3.5 } },
    lastVisit: "2024-09-14",
  },
  {
    title: "Mie Ayam Bakso Solo Wonogiri",
    totalPrice: 73000,
    category: ["eatery"],
    menus: [
      { name: "Bakso Lengkap Tetelan", price: 32000 },
      { name: "Es Temulawak", price: 6000 },
      { name: "Es Teh Tawar", price: 3000 },
    ],
    ourReview: { rating: { derby: 3.8, riska: 3.5 } },
    lastVisit: "2024-09-21",
  },
  {
    title: "Seiya Ramen",
    totalPrice: 216000,
    category: ["eatery"],
    menus: [
      { name: "Gyu-Jan Ramen + Egg", price: 71000 },
      { name: "Gyu-Jan Ramen Special", price: 78000 },
      { name: "Kaarage Set (3pcs)", price: 18000 },
      { name: "Ice Lemon Tea", price: 20000 },
      { name: "Ocha", price: 15000 },
    ],
    additionalCost: { ppn: "10%", serviceCharge: "5%" },
    ourReview: { rating: { derby: 4.5, riska: 4.8 } },
    lastVisit: "2024-09-15",
  },
  {
    title: "Conato",
    totalPrice: 67000,
    category: ["bakery"],
    menus: [
      { name: "Croissant Fla", price: 18000 },
      { name: "Croissant Almond", price: 20000 },
      { name: "Bun Coklat Kacang", price: 11000 },
    ],
    ourReview: { rating: { derby: 4, riska: 0 } },
    lastVisit: "2024-09-22",
  },
  {
    title: "Ayam Kremes Panjer",
    totalPrice: 56000,
    category: ["eatery"],
    menus: [
      { name: "Bebek Kremes", price: 24000 },
      { name: "Paket Hot 1", price: 16000 },
      { name: "Nasi", price: 6000 },
      { name: "Es Teh", price: 5000 },
    ],
    ourReview: { rating: { derby: 4.2, riska: 0 } },
    lastVisit: "2024-10-05",
  },
  {
    title: "Bali Banana",
    totalPrice: 42000,
    category: ["bakery"],
    menus: [{ name: "Banana Cake Choco", price: 42000 }],
    ourReview: { rating: { derby: 4.2, riska: 0 } },
    lastVisit: "2024-11-18",
  },
  {
    title: "Baliku Jamur",
    totalPrice: 102000,
    category: ["eatery"],
    menus: [
      { name: "Cireng Bumbu Rujak", price: 15455 },
      { name: "Twist Curly Jam Mozzarella", price: 27273 },
      { name: "TangJamSpagSpag Chicken Pop", price: 37273 },
      { name: "Es Teh", price: 5455 },
      { name: "Es Teh Leci", price: 7273 },
    ],
    additionalCost: { ppn: "10%" },
    ourReview: { rating: { derby: 3.2, riska: 0 } },
    lastVisit: "2024-10-05",
  },
  {
    title: "Hotway's Chicken",
    totalPrice: 55000,
    category: ["eatery"],
    menus: [
      { name: "Paket Nashville B", price: 27000 },
      { name: "Paket Crispy C", price: 21000 },
      { name: "Big Iced Tea", price: 7000 },
    ],
    ourReview: { rating: { derby: 4, riska: 0 } },
    lastVisit: "2024-11-02",
  },
  {
    title: "Agem Sa Boh",
    totalPrice: 76000,
    category: ["eatery"],
    menus: [
      { name: "Mie Bangladesh Jumbo Spesial", price: 35000 },
      { name: "Air Mineral", price: 5000 },
      { name: "Es Kosong", price: 1000 },
      { name: "Sirup Aceh", price: 10000 },
      { name: "Sate Kerang", price: 5000 },
      { name: "Sate Telur Puyuh", price: 5000 },
      { name: "Sate Kulit Ayam", price: 5000 },
      { name: "Sate Usus Ayam", price: 5000 },
      { name: "Sate Bakso", price: 5000 },
    ],
    ourReview: { rating: { derby: 3, riska: 0 } },
    lastVisit: "2024-11-16",
  },
  {
    title: "Charlie's",
    totalPrice: 27000,
    category: ["snack"],
    menus: [{ name: "BBQ Burger", price: 27000 }],
    ourReview: { rating: { derby: 4.4, riska: 0 } },
    lastVisit: "2024-09-01",
  },
  {
    title: "Mie Ayam Benteng 24",
    totalPrice: 64000,
    category: ["eatery"],
    menus: [
      { name: "Mie Ayam Pangsit", price: 20000 },
      { name: "Baso", price: 17000 },
      { name: "Es Teh Manis", price: 4000 },
      { name: "Es Teh Tawar", price: 3000 },
    ],
    ourReview: { rating: { derby: 4, riska: 4.2 } },
    lastVisit: "2024-11-24",
  },
  {
    title: "Ayam Tulak Lunak Bu Rahayu",
    totalPrice: 52000,
    category: ["eatery"],
    menus: [
      { name: "Ayam Bakar Tulang Lunak", price: 24000 },
      { name: "Ayam Goreng Tulang Lunak", price: 23000 },
      { name: "Es Teh", price: 5000 },
    ],
    ourReview: { rating: { derby: 4, riska: 4.2 } },
    lastVisit: "2024-11-09",
  },
];
